import React from "react";
import "./styles/BlogsCard.css"; 

function BlogsCard({ blogImg, blogHead, blogPara, blogProfile, blogAuthor, blogTimeline }) {
  return (
    <div className="blog-card">
      <div className="blog-image">
        <img src={blogImg} alt="blog-img" />
      </div>
      <h3 className="blog-title">{blogHead}</h3>
      <p className="blog-description">{blogPara}</p>
      <div className="blog-author">
        <div className="author-image">
          <img src={blogProfile} alt="blog-profile" />
        </div>
        <div className="author-details">
          <p className="author-blog">{blogAuthor}</p>
          <div className="author-meta">
            <p>{blogTimeline[0]}</p>
            <ul>
              <li>{blogTimeline[1]}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogsCard;
