import logoGoogle from "../img/logo_google.svg";
import logoFlipkart from "../img/logo-flipkart.svg";
import logoAdobe from "../img/logo-adobe.svg";
import logoMicrosoft from "../img/logo-microsoft.svg";
import logoAmazon from "../img/logo-amazon.svg";
import logoWalmart from "../img/logo-walmart.svg";
import logoRazorpay from "../img/logo-razorpay.svg";
import logoPaytm from "../img/logo-paytm.svg";
import logoOracle from "../img/logo-oracle.svg";
import logoPaloAlto from "../img/palo-alto.svg";
import logoTurvo from "../img/turvo-logo-img.svg";

var upperCase = /(?=.*?[A-Z])/;
var specialChar = /(?=.*?[#?!@$%^&*-])/;
const minLength = /.{8,}/;
const ATLEAST_ONE_UPPERCASE = /(?=.*[A-Z])/;
const ATLEAST_ONE_LOWERCASE = /(?=.*[a-z])/;
const ATLEAST_ONE_SPECIAL_CHARACTER = /(?=.*[!@#$%^&*])/;
const ATLEAST_ONE_NUMBER = /(?=.*[0-9])/;

const companyLogos = [
    logoGoogle,
    logoFlipkart,
    logoAdobe,
    logoMicrosoft,
    logoAmazon,
    logoWalmart,
    logoRazorpay,
    logoPaytm,
    logoOracle,
];

const ALUMNI_CONTENT = [
    {
        name: "Harshit Jaiswal",
        company_logo: logoTurvo,
        desc: "Programming Pathshala has the best course. You don't know what you get until you enroll and start preparing. It is the most valuable course available online and you're getting “Gold in the price of Silver”.",
    },
    {
        name: "Zeal Vagadia",
        company_logo: logoAmazon,
        desc: "Whatever I am today, I owe it to Programming Pathshala and their life changing course. I think it is pretty much all we need to build problem solving skills. The syllabus is designed in such a way that even a beginner can crack Top Tech Companies",
    },
    {
        name: "Balvindar Kaur",
        company_logo: logoPaloAlto,
        desc: "The course covers everything in depth and in a very structured way. There is never enough to learn and grow. I felt very prepared for my interviews after being a part of this course, and could enhance my DSA and problem solving skills in greater depth.",
    },
];

export {
    upperCase,
    specialChar,
    minLength,
    ATLEAST_ONE_UPPERCASE,
    ATLEAST_ONE_LOWERCASE,
    ATLEAST_ONE_SPECIAL_CHARACTER,
    ATLEAST_ONE_NUMBER,
    companyLogos,
    ALUMNI_CONTENT,
};
