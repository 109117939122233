import React, { useEffect, useState } from "react";
import {
    availableCourseInfo,
    COURSE_STATUS,
    COURSE_STATUS_BUTTON_TEXT_MAP,
    NEW_COURSES,
} from "./CourseSelectionScreenConstants";
import Button from "@material-ui/core/Button";
import "./CourseSelectionScreen.css";
import { setCurrentCourse } from "../CourseUtility";
import { useNavigate } from "react-router-dom";
import PPALogo from "../../img/PPA_Logo.svg";
import Loader from "../../components/Loader/Loader";
// import { initiateFreeTrialForExistingUsers } from "../../components/StartFreeTrial/StartFreeTrialClient";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import redirectToLandingPageStrategy from "../Strategies/LandingPageRedirectionStrategy";
import SubscribedTag from "../../resources/images/Subscribed.svg";
import FreeTrialTag from "../../resources/images/FreeTrial.svg";
import ExpiredTag from "../../resources/images/Expired.svg";
import PausedTag from "../../resources/images/OnPause.svg";
import newTag from "../../resources/images/NewSticker.svg";
import UniversityLicenseTag from "../../resources/images/universityLicenseTag.svg";
import { useGetIsWhiteLabeledUser } from "../../hooks/useGetIsWhiteLabeledUser";
import { openUniversityDashboard } from "../../Utilities/ReRoutingUtils";

const getCourseInfo = (isSubscribedResponse) => {
    let activeCourseInfo = {
        Response: {},
        LLD: {},
        CRASH_COURSE: {},
        REACT: {},
    };

    // add all courses that user is subscribed to
    Object.keys(isSubscribedResponse).forEach((courseIdentifier) => {
        const courseSubscriptionDetails = isSubscribedResponse[courseIdentifier].Subscription;
        let courseStatus;

        if (
            courseSubscriptionDetails.pauseDetails &&
            courseSubscriptionDetails.pauseDetails.isCurrentlyOnPause
        )
            courseStatus = COURSE_STATUS.ON_PAUSE;
        else if (courseSubscriptionDetails.onTrial) courseStatus = COURSE_STATUS.ON_FREE_TRIAL;
        else if (courseSubscriptionDetails.status) courseStatus = COURSE_STATUS.SUBSCRIBED;
        else if (courseSubscriptionDetails.everSubscribed) courseStatus = COURSE_STATUS.EXPIRED;
        else courseStatus = COURSE_STATUS.START_TRIAL;

        if (courseStatus !== COURSE_STATUS.START_TRIAL)
            activeCourseInfo[courseIdentifier] = {
                ...availableCourseInfo[courseIdentifier],
                courseStatus,
            };
    });

    // add remaing courses that user has not subscribed to
    Object.keys(availableCourseInfo).forEach((courseIdentifier) => {
        if (!isSubscribedResponse.hasOwnProperty(courseIdentifier))
            activeCourseInfo[courseIdentifier] = {
                ...availableCourseInfo[courseIdentifier],
                courseStatus: COURSE_STATUS.START_TRIAL,
            };
    });

    return activeCourseInfo;
};

const isNewCourse = (courseStatus, courseName) => {
    if (courseStatus === COURSE_STATUS.START_TRIAL && NEW_COURSES.includes(courseName))
        return <img src={newTag} className="new-course" />;
};

const getStatusOfCourse = (whiteLabeledCourses, course) => {
    const { courseStatus } = course;
    const coursesList = whiteLabeledCourses?.map((item) => {
        if (item.toLowerCase() === "response") {
            return "renaissance";
        }
        return item.toLowerCase();
    });
    if (coursesList?.includes(course.courseName.toLowerCase())) {
        return <img className="course-image-tag" src={UniversityLicenseTag} />;
    } else if (courseStatus === COURSE_STATUS.ON_FREE_TRIAL)
        return <img className="course-image-tag" src={FreeTrialTag} />;
    else if (courseStatus === COURSE_STATUS.SUBSCRIBED)
        return <img className="course-image-tag" src={SubscribedTag} />;
    else if (courseStatus === COURSE_STATUS.EXPIRED)
        return <img className="course-image-tag" src={ExpiredTag} />;
    else if (courseStatus === COURSE_STATUS.ON_PAUSE)
        return <img className="course-image-tag" src={PausedTag} />;
    else return <></>;
};

const handleStartTrial = async (redirectUser, setIsLoading) => {
    redirectUser("/start-free-trial");
};

export default function CourseSelectionScreen() {
    const [isLoading, setIsLoading] = useState(false);
    const [activeCourseInfo, setActiveCourseInfo] = useState({});
    const { checkSubscribed, freeTrialFormFilled } = useGlobalContext();
    const { whiteLabeledUserInfo, whiteLabeledUserInfoLoading } = useGetIsWhiteLabeledUser();

    useEffect(async () => {
        try {
            setIsLoading(true);
            const checkSubscribedResponse = await checkSubscribed();
            const subscriptionData =
                checkSubscribedResponse && checkSubscribedResponse.data
                    ? checkSubscribedResponse && checkSubscribedResponse.data
                    : {};

            const courseInfo = getCourseInfo(subscriptionData);

            setActiveCourseInfo(courseInfo);
        } catch (err) {
            console.error("Error fetching subscription data : " + err);
            redirectToLandingPageStrategy();
        } finally {
            setIsLoading(false);
        }
    }, []);

    // navigation
    const navigate = useNavigate();
    const redirectUser = (link) => navigate(link, { replace: true });

    // button handlers
    const handleCourseSelect = async (course) => {
        setCurrentCourse(course.courseName);

        const coursesList = whiteLabeledUserInfo?.courses?.map((item) => {
            if (item.toLowerCase() === "response") {
                return "renaissance";
            }
            return item.toLowerCase();
        });
        if (coursesList?.includes(course.courseName?.toLowerCase())) {
            openUniversityDashboard();
        } else if (
            course.courseStatus === COURSE_STATUS.SUBSCRIBED ||
            course.courseStatus === COURSE_STATUS.ON_FREE_TRIAL ||
            course.courseStatus === COURSE_STATUS.ON_PAUSE ||
            course.courseStatus === COURSE_STATUS.EXPIRED
        ) {
            window.open(`${process.env.REACT_APP_NEW_DASHBOARD_URL}/${course.courseName}`, "_self");
        } else if (course.courseStatus === COURSE_STATUS.START_TRIAL) {
            await handleStartTrial(redirectUser, setIsLoading);
        }
    };
    const getHeader = () => {
        return (
            <div className="selection-screen-header-wrapper">
                <img src={PPALogo} className="selection-screen-logo" />
                <h2 className="selection-screen-heading">Welcome to Programming Pathshala</h2>
                <div className="selection-screen-subheading">Let’s start learning...</div>
            </div>
        );
    };

    const getCourseCard = (course) => {
        return (
            <div className="selection-screen-course-card-wrapper">
                {getStatusOfCourse(whiteLabeledUserInfo.courses, course)}
                {isNewCourse(course.courseStatus, course.courseName)}

                <div className="selection-screen-course-content">
                    <h2 className="selection-screen-course-title">{course.courseTitle}</h2>
                    <div className="selection-screen-course-description">
                        {course.courseDescription}
                    </div>
                </div>

                <div className="selection-screen-course-button-wrapper">
                    <button
                        className="selection-screen-course-button"
                        onClick={() => handleCourseSelect(course)}
                        disabled={whiteLabeledUserInfoLoading || false}
                    >
                        {COURSE_STATUS_BUTTON_TEXT_MAP[course.courseStatus]}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="selection-screen-container">
            {isLoading ? <Loader /> : <></>}
            {getHeader()}

            <div className="selection-screen-courses">
                {Object.values(activeCourseInfo) &&
                    Object.values(activeCourseInfo).map((course) => getCourseCard(course))}
            </div>
        </div>
    );
}
