import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import "./styles/Intro.css";
import { Rating } from "@mui/material";
import pythonIntroIllustrator from "../../img/python-intro-mob.webp";
import { PythonIntroBannerContents } from "./Content/PythonIntroContents";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { getCurrentCourse } from "../../courses/CourseUtility";
import { goToDashboard, startFreeTrial } from "../../courses/Strategies/RedirectionStrategy";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

const Intro = () => {
    const IntroBannerContents = PythonIntroBannerContents;

    const navigate = useNavigate();
    const redirectUser = (link) => navigate(link);
    const { loggedIn, subscribed, everSubscribed, freeTrialFormFilled } = useGlobalContext();

    const { windowWidth } = useWindowDimensions();
    const getFreeTrialButton = () => {
        return (
            <div className="python-intro-cta-btn-wrapper">
                <button
                    onClick={() => {
                        startFreeTrial(freeTrialFormFilled, redirectUser);
                    }}
                >
                    Start Free Trial
                </button>
            </div>
        );
    };

    const getDashBoardButton = () => {
        return (
            <div className="python-intro-cta-btn-wrapper">
                <button onClick={() => goToDashboard()}>Go to Dashboard</button>
            </div>
        );
    };

    const getSignupButton = () => {
        return (
            <div className={`${GTM_TRACKER.START_FREE_TRIAL} python-intro-cta-btn-wrapper`}>
                {" "}
                <button onClick={() => navigate("/signup")}>Try 7-Day Free Trial</button>
            </div>
        );
    };

    const getFreeTrailOrDashboardButton = () => {
        if (!loggedIn) return getSignupButton();
        else if (!everSubscribed) return getFreeTrialButton();
        else return getDashBoardButton();
    };

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    return (
        <>
            <div className="python-intro-wrapper">
                <div className="python-intro-content">
                    <div className="python-star-rating-wrapper">
                        <Rating
                            name="half-rating-read"
                            defaultValue={5}
                            readOnly
                            size="small"
                            sx={{
                                fontSize: windowWidth <= 600 ? "4.27vw" : "1.36vw",
                                color: "#2E3E5C",
                            }}
                        />
                        <p className="python-star-rating-text">
                            Our courses are rated <strong>4.9</strong>/5 by{" "}
                            <strong>5k+ students</strong>
                        </p>
                    </div>
                    {IntroBannerContents.introCourseHeading}
                    {IntroBannerContents.introCourseDescription}
                    {IntroBannerContents.introPoints &&
                        IntroBannerContents.introPoints.map((introPoint) => (
                            <div className="python-intro-points">
                                <img src={introPoint.img} alt="" />
                                <p>{introPoint.text}</p>
                            </div>
                        ))}
                    {getFreeTrailOrDashboardButton()}
                    <span className="python-free-lectures">
                        Watch 10+ lectures and a case study for&nbsp;<strong>free </strong>
                    </span>
                </div>
                <div className="python-intro-image">
                    <img
                        className="python-illustrator-img"
                        src={pythonIntroIllustrator}
                        alt="Python Full Course"
                    ></img>
                </div>
            </div>
        </>
    );
};

export default Intro;
