import Swiggy from "../../img/Swiggy.svg";
import Quora from "../../img/Quora.svg";
import Payments from "../../img/Payments.svg";
import Video from "../../img/Video.svg";
import Pencil from "../../img/Pencil.svg";
import PersonStar from "../../img/PersonStar.svg";
import TimeClockBlue from "../../img/TimeClockBlue.svg";
import Mentor from "../../img/Mentor.svg";
import DocumentBlue from "../../img/DocumentBlue.svg";
import Target1 from "../../img/Target1.svg";
import Target2 from "../../img/Target2.svg";
import Target3 from "../../img/Target3.svg";
import Target4 from "../../img/Target4.svg";

const CardDataProjects = [
    {
        imgSrc: Swiggy,
        heading: "Swiggy Backend: End to End Project",
        paragraph:
            "This project will demonstrate your skills in web development, database integration, and security.",
        tags: ["Springdata JPA", "MySQL or PostgreSQL", "Spring Security"],
        alt: Swiggy
    },
    {
        imgSrc: Quora,
        heading: "Building Quora using Micro-Service in Springboot",
        paragraph: `We will develop a microservice for user authentication and management. Users can register, log in, and manage their profiles through this service.`,
        tags: ["Microservice", "Docker", "Kubernetes"],
        alt: Quora
    },
    {
        imgSrc: Payments,
        heading: "Bring a Payment Service using Transaction and Springboot",
        paragraph:
            "This project will demonstrate your skills in web development, microservices architecture, and cloud computing.",
        tags: ["Web Development", "Cloud Computing", "Microservice Architecture"],
        alt: "Learn Backend Engineering in JAVA Spring Boot Framework"
    },
];

const CardDataWhyChoose = [
    {
        icon: Video,
        heading: "Self-Paced Learning",
        paragraph:
            "Learn at your own pace with structured lessons to master key concepts effectively.",
    },
    {
        icon: Pencil,
        heading: "Hands-On Projects",
        paragraph:
            "Engage in real-world projects to apply your knowledge and build a strong portfolio.",
    },
    {
        icon: PersonStar,
        heading: "Expert Instructors",
        paragraph:
            "Learn from industry veterans with years of experience in JAVA Spring Framework.",
    },
    {
        icon: TimeClockBlue,
        heading: "Flexible Schedule",
        paragraph:
            "Study at your own pace with our self-paced learning modules designed to fit your busy lifestyle.",
    },
    {
        icon: Mentor,
        heading: "Doubt Resolution by Mentor",
        paragraph:
            "Get your questions answered with personalized guidance from our experienced mentors.",
    },
    {
        icon: DocumentBlue,
        heading: "Certification",
        paragraph:
            "Earn a recognized certificate upon completion to boost your professional credentials.",
    },
];

const CardDataAudience = [
    {
        heading: "College Student or Fresher",
        paragraph: (
            <>
                Missing projects and technologies in your resume?
                <br />
                Learn the hottest backend skills and build an impressive project.
            </>
        ),
        imageSrc: Target1,
        alt: Target1,
    },
    {
        heading: "Backend Working Professional",
        paragraph:
            "Enhance your skillset to become a well rounded backend developer  for seamless transition and excelling in your career path.",
        imageSrc: Target2,
        alt: "Become a skilled backened developer with our Spring Boot Course"
    },
    {
        heading: "Developer switching to Backend",
        paragraph:
            "Leverage your existing knowledge and gain the specialized skills to develop, and deploy robust backend systems.",
        imageSrc: Target3,
        alt: "Switch to Backend with Programming Pathshala's spring boot course"
    },
    {
        heading: "Aspiring Developer or Enthusiast",
        paragraph:
            "Stand out with real-world backend skills while in college and prepare for top backend development roles.",
        imageSrc: Target4,
        alt: Target4
    },
];

const CardDataOutcomes = [
    {
        id: 0,
        heading: "Fundamentals",
        paragraph:
            "Master OOPs concepts, design principles, reflection with java.lang.reflect, robust exception handling, and Maven for build automation and dependency management.",
    },
    {
        id: 1,
        heading: "Spring Boot and Spring MVC Internals",
        paragraph:
            "Dive into the inner workings of Spring MVC, including its architecture, request flow, controllers, and view resolution mechanisms.",
    },
    {
        id: 2,
        heading: "JDBC, ORM, Hibernate",
        paragraph:
            "Understand JDBC for database connectivity, ORM principles, and how to use Hibernate for efficient data persistence.",
    },
    {
        id: 3,
        heading: "Spring Data JPA",
        paragraph:
            "Master Spring Data JPA for simplified data access and management using the Java Persistence API (JPA) and Spring Framework integration.",
    },
    {
        id: 4,
        heading: "Authentication and Authorization",
        paragraph:
            "Learn how to implement robust authentication and authorization mechanisms using Spring Security, ensuring secure access control to your applications.",
    },
    {
        id: 5,
        heading: "Spring Security",
        paragraph:
            "Master Spring Security to implement comprehensive authentication, authorization, and secure communication in your Java applications.",
    },
];

const TabsData = [
    {
        title: "Fundamentals",
        module: "Module 1",
    },
    {
        title: "Spring Boot and Spring MVC Internals",
        module: "Module 2",
    },
    {
        title: "Concurrency Concepts",
        module: "Module 3",
    },
    {
        title: "JDBC, ORM, Hibernate",
        module: "Module 4",
    },
    {
        title: "Spring Data JPA",
        module: "Module 5",
    },
    {
        title: "Authentication and Authorization",
        module: "Module 6",
    },
    {
        title: "Spring Security",
        module: "Module 7",
    },
];

const InformationData = [
    [
        "OOPS Concepts and Design Principles.",
        `Reflection, java.lang.reflect library and its relevance in Spring.`,
        "Exception Handling in a production system.",
        "Maven - Build Automation Tool, Dependencies, Jar files etc",
    ],
    [
        "Core concepts : Dependency Injection and Inversion of Control.",
        "Beans and Types of Autowiring.",
        "In-depth exploration of the Codebase of Spring MVC and its low level architecture - Dispatcher Servlet, Handler Mappings, Adapters, Execution Chains and many more…",
        "Interceptors, Filters and User defined customisations.",
        "Different ways of handling exceptions and their translations.",
        "Reading and managing Config files.",
        "Journey of a Web Request till API layer.",
    ],
    [
        "Threads, Locks and Race Conditions",
        "Memory Visibility issues",
        "Thread Signaling and Synchronisation",
        "Producer Consumer Problem",
        "Thread Pools and Executor Service",
    ],
    [
        "Architecture and Codebase of JDBC : DB Drivers, Connections, ResultSets and many more",
        "Batch Queries, Connection Pools and Prepared Statements.",
        "Object Oriented vs Relational Paradigm.",
        "Internals of JPA and Hibernate : Entities, State Management, Dirty Checking etc",
        "Some Handy Annotations and Constructs in Hibernate.",
        "Modeling Join operations on Tables, Lazy vs Eager loading of data.",
        "Transactions",
    ],
    [
        "Proxy Classes, Associations and their applications.",
        "JPQL, Custom Queries etc",
        "Useful annotations",
    ],
    [
        "Difference between authentication and authorization.",
        "Brute-force way of authentication.",
        "JWT way of authentication.",
        "Different types of web vulnerabilities.",
    ],
    [
        "In-depth understanding of codebase and architecture of Spring Security.",
        "Authentication Filters, Security Context, Password Manager etc",
        "Customizing and extending the functionalities.",
    ],
];

const SpringbootFaqData = [
    {
        id: 1,
        question: "What Job Opportunities Are There After Learning Spring Boot?",
        answer: "Once you finish this course, you can aim for roles like Spring Boot Developer, Java Developer, Web Developer, and Software Engineer.",
    },
    {
        id: 2,
        question: "What Will I Be Able to Create After This Course?",
        answer: "By the end of the course, you'll know how to build simple web applications with Spring Boot. You'll also be ready to tackle more complex projects and learn advanced Spring Boot topics.",
    },
    {
        id: 3,
        question: "Will I Get a Certificate After Completing the Course?",
        answer: "Yes, you'll receive a free certificate once you complete the course successfully.",
    },
    {
        id: 4,
        question: "How Long Will It Take to Finish This Course?",
        answer: "It depends on you! The course material is designed to take 3 months, but since it's self-paced, you can complete it according to your own schedule.",
    },
    {
        id: 5,
        question: "What Is SpringBoot?",
        answer: "Spring Boot is a popular framework for building web applications with Java. It makes it easy to create stand-alone, production-ready apps with minimal setup.",
    },
    {
        id: 6,
        question: "Do I Need Any Prior Programming Experience?",
        answer: "No prior programming experience is necessary, but a basic understanding of Java will be helpful.",
    },
    {
        id: 7,
        question: "How Do I Access the Course Materials?",
        answer: "All course materials are available online. Once you sign up, you'll have access to everything you need through our learning platform.",
    },
    {
        id: 8,
        question: "Is There Any Support Available If I Get Stuck?",
        answer: "Yes, there is! You can voice your doubts in discussion forums, or attend live Q&A sessions with instructors.",
    },
    {
        id: 9,
        question: "Can I Interact with Other Students?",
        answer: "Absolutely! We encourage interaction through our online community and discussion forums where you can share ideas and ask questions.",
    },
];

const TagsData = [
    {
        id: 1,
        text: "💻 Self-Paced Learning"
    },
    {
        id: 2,
        text: "🚀 3-Industry Projects"
    },
    {
        id: 3,
        text: "📚 In-depth Coursework"
    },
    {
        id: 4,
        text: "✅ Resume Enhancement"
    },
    {
        id: 5,
        text: "🎓 3-Months Duration",
    }
]
export {
    CardDataProjects,
    CardDataWhyChoose,
    CardDataAudience,
    CardDataOutcomes,
    TabsData,
    InformationData,
    SpringbootFaqData,
    TagsData
};
