import React from "react";
import RefundPolicyBannerImg from "../../../img/RefundPolicyBannerImg.png";
import "./RefundBanner.css";

const getRefundBannerContent = () => {
    return (
        <div className="refund-policy-content-wrapper">
            <h1>7 Days Refund Policy</h1>
            <p>Learn more about Programming Pathshala’s refund and cancellation policy.</p>
        </div>
    );
};

const getRefundBannerImg = () => {
    return (
        <div className="refund-policy-img-wrapper">
            <img src={RefundPolicyBannerImg} alt="refund-policy-banner" />
        </div>
    );
};

const RefundBanner = () => {
    return (
        <div className="refund-policy-banner-wrapper">
            {getRefundBannerContent()}
            {getRefundBannerImg()}
        </div>
    );
};

export default RefundBanner;
