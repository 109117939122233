import React from "react";
import Header from "../../Header/Header";
import Footer from "../../LandingPage/Footer";
import RefundPolicy from "../RefundPolicy";
import { Helmet } from "react-helmet-async";

const RefundPolicyRoute = () => {
    return (
        <div className="">
            <Helmet>
                <title>Refund Policy | Programming Pathshala</title>
                <meta
                    name="description"
                    content="Read Refund Policy of Programming Pathshala."
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/refund-policy"/>
            </Helmet>
            <div className="header-sticky">
                <Header />
            </div>
            <div>
                <RefundPolicy />
            </div>
            <div>
                <div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default RefundPolicyRoute;
