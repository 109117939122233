import React from "react";
import "./styles/Prerequisites.css";
import prerequisiteImg from "../../img/python-prerequisitewing.png";

const Prerequisites = () => {
    return (
        <div className="python-pre-requisites-wrapper">
            <div className="python-pre-requisites-image-left">
                <img src={prerequisiteImg} alt="" />
            </div>
            <div className="python-pre-requisites-content">
                <p className="customPreRequisitesHeading">But wait, what are the pre-requisites for this course?</p>
                <p className="none">None</p>
                <p>No prior experience in any programming language is required.
                    <span className="python-pre-requisites-text"> Just enthusiasm to learn!</span>
                </p>
            </div>
            <div className="python-pre-requisites-image-right">
                <img src={prerequisiteImg} alt="" />
            </div>
        </div>
    );
};

export default Prerequisites;
