import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import "./styles/PrevArrow.css";

function PrevArrow(props) {
  const { onClick, top, right } = props;
  return (
    <div
      style={{ top: `${top}px`, right: `${right}%` }}
      className="prev-arrow"
      onClick={onClick}
    >
      <FaChevronLeft className="prev-arrow-icon" />
    </div>
  );
}

export default PrevArrow;
