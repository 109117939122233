import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Intro from "./Intro";
import About from "./About";
import Audience from "./Audience";
import Outcome from "./Outcome";
import Curriculum from "./Curriculum";
import Prerequisites from "./PreRequisites";
import Mentor from "../Mentor/Mentor";
import Scholarship from "./Scholarship";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "./FreeTrial";
import SuccessStories from "./SuccessStories";
import Pricing from "./Pricing/Pricing";
import Faq from "./Faq";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import Cookies from "universal-cookie";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import {
    setUTMParametersInLocalStorage,
    setUtmCouponCodeInCookie,
    useQuery,
} from "../../Utilities/CommonUtilities";
import { Helmet } from "react-helmet";

const CppLandingPageRoute = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.CPP);
    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);
    setUtmCouponCodeInCookie(query);

    return (
        <div>
            <Helmet>
                <title>Best C++ Course with Certification || Programming Pathshala</title>
                <meta
                    name="description"
                    content="Master C++ from basics to advanced. Best C++ course with certification! Learn problem-solving, competitive programming & more. Try a 7-day free trial today!"
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/begin-with-c++" />
                <script type="application/ld+json">{
                `{
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "url": "https://renaissance.programmingpathshala.com/begin-with-c++",
                    "name": "Best C++ Course with Certification - Renaissance by Programming Pathshala",
                    "description": "Master C++ from basics to advanced. Best C++ course with certification! Learn problem-solving, competitive programming & more. Try a 7-day free trial today!",
                    "publisher": {
                      "@type": "Organization",
                      "name": "Programming Pathshala",
                      "url": "https://renaissance.programmingpathshala.com",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "/ppa-icon-img.png"
                      }
                    },
                    "mainEntity": {
                      "@type": "EducationalOrganization",
                      "name": "Programming Pathshala",
                      "url": "https://renaissance.programmingpathshala.com/begin-with-c++",
                      "sameAs": [
                        "https://www.facebook.com/programmingpathshala",
                        "https://www.linkedin.com/company/programming-pathshala",
                        "https://www.instagram.com/programmingpathshala/"
                      ]
                    },
                    "hasCourse": {
                      "@type": "Course",
                      "name": "Best C++ Course with Certification",
                      "description": "Master C++ from basics to advanced. Learn problem-solving, competitive programming & more. Try a 7-day free trial today!",
                      "provider": {
                        "@type": "Organization",
                        "name": "Programming Pathshala",
                        "sameAs": "https://renaissance.programmingpathshala.com/begin-with-c++"
                      },
                      "courseMode": "Online",
                      "educationalLevel": "Beginner to Advanced",
                      "educationalCredentialAwarded": "Certificate of Completion",
                      "offers": {
                        "@type": "Offer",
                        "category": "Paid",
                        "price": "4999",
                        "priceCurrency": "INR",
                        "availability": "https://schema.org/InStock",
                        "url": "https://renaissance.programmingpathshala.com/signup"
                      },
                      "hasCourseInstance": {
                        "@type": "CourseInstance",
                        "courseMode": "Online",
                        "courseWorkload": "PT10H",
                        "courseSchedule": {
                          "@type": "Schedule",
                          "repeatFrequency": "Daily",
                          "repeatCount": "31"
                        },
                        "instructor": {
                          "@type": "Person",
                          "name": "Vivekanand Vivek"
                        },
                        "location": {
                          "@type": "VirtualLocation",
                          "url": "https://renaissance.programmingpathshala.com"
                        }
                      }
                    }
                }`
                }
                </script>
                <meta property="og:title" content="Best C++ Course with Certification" />
                <meta property="og:description" content="Master C++ from basics to advanced. Best C++ course with certification! Learn problem-solving, competitive programming & more. Try a 7-day free trial today!" />
                <meta property="og:url" content="https://renaissance.programmingpathshala.com/begin-with-c++" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="/ppa-icon-img.png" />
                
                
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Best C++ Course with Certification" />
                <meta name="twitter:description" content="Master C++ from basics to advanced. Best C++ course with certification! Learn problem-solving, competitive programming & more. Try a 7-day free trial today!" />
                <meta name="twitter:image" content="/ppa-icon-img.png" />
                <meta name="twitter:url" content="https://renaissance.programmingpathshala.com/begin-with-c++" />
            </Helmet>
            {/* <div>
                <SpringBanner />
            </div> */}
            <div className="header-sticky">
                <Header setCouponCode={setCouponCode}/>
            </div>
            <Intro />
            <About />
            <Audience />
            <Outcome />
            <Curriculum />
            <Prerequisites />
            <Mentor course={availableCourses.CPP} />
            <SuccessStories />
            <Pricing couponCode={couponCode} setCouponCode={setCouponCode} />
            <Scholarship />
            <Faq />
            <ExploreCourses course={availableCourses.CPP} />
            <ExploreBlogs course={availableCourses.CPP} />
            <FreeTrial />
            <Footer />
        </div>
    );
};

export default CppLandingPageRoute;
