import React from "react";
import Slider from "react-slick";
import arrowPrev from "../../img/left-arrow-blue.png";
import arrowNext from "../../img/right-arrow-blue.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import dsaAdvantageImg from "../../img/dsa-advantage.png";
import { DsaAdvantagesContent } from "./DsaCrashCourseContents/DsaAdvantagesContent";
import "./styles/Advantage.css";

function NextArrow(props) {
    const { className, onClick } = props;
    return (
        <img className={className} onClick={onClick} src={arrowNext} alt="next" />
    );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />
    );
}
const Advantage = () => {
    const advantageContents = DsaAdvantagesContent;
    const { windowWidth } = useWindowDimensions();

    const getContent = () => {
        return (
            <div className="dsa-advantage-content">
                <img src={dsaAdvantageImg} alt="Clear coding interview easily" />
                <div className="dsa-advantage-card-wrapper">
                    {advantageContents.AdvantagesList &&
                        advantageContents.AdvantagesList.map((advantage) => (
                            <div className="dsa-advantage-card">
                                <div className="dsa-advantage-data">
                                    <div className="dsa-advantage-type">{advantage.type}</div>
                                    <div className="dsa-advantage-text">{advantage.text}</div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    };
    const getContentForMob = () => {
        const sliderSettings = {
            dots: true,
            infinite: false,
            autoplay: false,
            speed: 500,
            slidesToShow: 3,
            arrows: true,
            slidesToScroll: 1,
            customPaging: (i) => {
                return <a />;
            },
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                    breakpoint: 601,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                    },
                },
            ],
        };
        return (
            <div className="dsa-advantage-content-mob-wrapper">
                <Slider {...sliderSettings}>
                    {advantageContents.AdvantagesList &&
                        advantageContents.AdvantagesList.map((advantage) => (
                            <div>
                                <div
                                    className="dsa-advantage-card-mob"
                                    style={{ backgroundColor: advantage.bgColor }}
                                >
                                    <img src={advantage.img} alt="" />
                                    <div className="dsa-advantage-type">{advantage.type}</div>
                                    <div className="dsa-advantage-text">{advantage.text}</div>
                                </div>
                            </div>
                        ))}
                </Slider>
            </div>
        );
    };
    return (
        <div className="dsa-advantage-wrapper">
            <div className="dsa-advantage-heading-wrapper">
                {advantageContents.sectionTitle}
                {advantageContents.sectionDescription}
            </div>
            {windowWidth > 600 ? getContent() : getContentForMob()}
        </div>
    );
};

export default Advantage;
