import React from "react";
import { useEffect } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import Cookies from "universal-cookie";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Intro from "./Intro";
import About from "./About";
import Audience from "./Audience";
import Advantage from "./Advantage";
import Curriculum from "./Curriculum";
import Prerequisites from "./Prerequisites";
import Mentor from "../Mentor/Mentor";
import SuccessStories from "./SuccessStories";
import Pricing from "./Pricing/Pricing";
import Faq from "./Faq";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "./FreeTrial";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import {
    setUTMParametersInLocalStorage,
    setUtmCouponCodeInCookie,
    useQuery,
} from "../../Utilities/CommonUtilities";
import NodeJsEventBanner from "../Header/NodeJsEventBanner";
import SpringBanner from "../Header/SpringBanner";
import { Helmet } from "react-helmet";

const PythonLandingPageRoute = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.PYTHON);
    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);
    setUtmCouponCodeInCookie(query);

    return (
        <div>
            <Helmet>
                <title>Python for Beginners & Professionals - Hands-on Course</title>
                <meta
                    name="description"
                    content="Master Python with our beginner-friendly course. Learn programming, DSA, and backend development. Try a 7-day free trial today!"
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/dsa-and-algorithms-essentials"/>
                <script type="application/ld+json">{
                `{
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "url": "https://renaissance.programmingpathshala.com/begin-with-python",
                    "name": "Begin with Python - Renaissance by Programming Pathshala",
                    "description": "Learn Python from scratch with our comprehensive course. Master object-oriented and functional programming, problem-solving, and backend development.",
                    "publisher": {
                      "@type": "Organization",
                      "name": "Programming Pathshala",
                      "url": "https://renaissance.programmingpathshala.com",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "/ppa-icon-img.png"
                      }
                    },
                    "mainEntity": {
                      "@type": "EducationalOrganization",
                      "name": "Programming Pathshala",
                      "url": "https://renaissance.programmingpathshala.com/begin-with-python",
                      "sameAs": [
                        "https://www.facebook.com/programmingpathshala",
                        "https://www.linkedin.com/company/programming-pathshala",
                        "https://www.instagram.com/programmingpathshala/"
                      ]
                    },
                    "hasCourse": {
                      "@type": "Course",
                      "name": "Begin with Python",
                      "description": "Start your programming journey with Python. Learn fundamental concepts, data structures, object-oriented programming, and problem-solving.",
                      "provider": {
                        "@type": "Organization",
                        "name": "Programming Pathshala",
                        "sameAs": "https://renaissance.programmingpathshala.com/begin-with-python"
                      },
                      "courseMode": "Online",
                      "educationalLevel": "Beginner to Advanced",
                      "educationalCredentialAwarded": "Certificate of Completion",
                      "offers": {
                        "@type": "Offer",
                        "category": "Paid",
                        "price": "4999",
                        "priceCurrency": "INR",
                        "availability": "https://schema.org/InStock",
                        "url": "https://renaissance.programmingpathshala.com/signup"
                      },
                      "hasCourseInstance": {
                        "@type": "CourseInstance",
                        "courseMode": "Online",
                        "courseWorkload": "PT10H",
                        "courseSchedule": {
                          "@type": "Schedule",
                          "repeatFrequency": "Daily",
                          "repeatCount": "31"
                        },
                        "instructor": {
                          "@type": "Person",
                          "name": "Vivekanand Vivek"
                        },
                        "location": {
                          "@type": "VirtualLocation",
                          "url": "https://renaissance.programmingpathshala.com"
                        }
                      }
                    }
                }`
                }
                </script>
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/begin-with-python" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://renaissance.programmingpathshala.com/begin-with-python" />
                <meta property="og:title" content="Begin with Python - Renaissance by Programming Pathshala" />
                <meta property="og:description" content="Learn Python from scratch with our comprehensive course. Master object-oriented and functional programming, problem-solving, and backend development." />
                <meta property="og:image" content="/ppa-icon-img.png" />
                <meta property="og:site_name" content="Programming Pathshala" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Begin with Python - Renaissance by Programming Pathshala" />
                <meta name="twitter:description" content="Learn Python from scratch with our comprehensive course. Master object-oriented and functional programming, problem-solving, and backend development." />
                <meta name="twitter:image" content="/ppa-icon-img.png" />
                <meta name="twitter:site" content="@programmingpathshala" />
            </Helmet>
            {/* <div>
                <SpringBanner />
            </div> */}
            <div className="header-sticky">
                <Header setCouponCode={setCouponCode}/>
            </div>

            <div>
                <Intro />
            </div>
            <div>
                <About />
            </div>
            <div>
                <Audience />
            </div>
            <div>
                <Advantage />
            </div>
            <div>
                <Curriculum />
            </div>

            <div>
                <Prerequisites />
            </div>

            <div>
                <Mentor course={availableCourses.PYTHON} />
            </div>
            <div>
                <SuccessStories />
            </div>
            <div>
                <Pricing couponCode={couponCode} setCouponCode={setCouponCode} />
            </div>
            <div>
                <Faq />
            </div>
            <div>
                <ExploreCourses course={availableCourses.PYTHON} />
            </div>
            <div>
                <ExploreBlogs course={availableCourses.PYTHON} />
            </div>

            <div>
                <FreeTrial />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};

export default PythonLandingPageRoute;
