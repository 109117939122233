import React from "react";
import "./styles/Community.css";
import community from "../../img/ren-community.png";

const Community = () => {
    const handleClick = () => {
        window.open("https://community.programmingpathshala.com/");
    };
    return (
        <div className="dsa-community-wrapper">
            <div className="dsa-community-image">
                <img src={community} alt="Learn DSA with programming pathshala" />
            </div>
            <div className="dsa-community-content">
                <h2>Join our Community, and Code Everyday.</h2>
                <p>
                    Consistency is what makes you perfect. Solve Problems
                    everyday with us by joining<br /> the Community.
                </p>
                <button className="dsa-join-community-btn" onClick={handleClick}>
                    Join Our Community
                </button>
            </div>
        </div>
    );
};

export default Community;
