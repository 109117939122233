import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import React, { useState } from "react";
import "./styles/faq.css";
import { SpringbootFaqData } from "./SpringBootData";

const FaqAccordion = ({ recordAccordionId, isOpen, identifier, accordionData }) => {
    const handleAccordionClick = () => {
        recordAccordionId(identifier);
    };

    return (
        <div onClick={handleAccordionClick} className="springboot-faq-accordion-wrapper">
            <div
                className={
                    (isOpen ? "springboot-faq-accordion-background" : " ") +
                    " springboot-faq-accordion-item"
                }
            >
                {" "}
                <div className="springboot-faq-accordion-question">
                    <h3>{accordionData.question}</h3>
                    {isOpen ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                </div>
                <div
                    className={
                        (isOpen ? "springboot-faq-accodrion-show" : " ") +
                        " springboot-faq-accodrion-content"
                    }
                >
                    <p>{accordionData.answer}</p>
                </div>
            </div>
        </div>
    );
};

const Faq = () => {
    const [openId, setOpenId] = useState(1);
    const recordAccordionId = (id) => {
        if (id === openId) setOpenId(-1);
        else setOpenId(id);
    };
    return (
        <div className="springboot-faq-wrapper">
            <h2>Frequently Asked Questions</h2>
            {SpringbootFaqData &&
                SpringbootFaqData.map((accordionData, index) => (
                    <div key={index}>
                        <FaqAccordion
                            identifier={accordionData.id}
                            key={index}
                            accordionData={accordionData}
                            recordAccordionId={recordAccordionId}
                            isOpen={accordionData.id === openId}
                        />
                    </div>
                ))}
        </div>
    );
};

export default Faq;
