import React from 'react';
import BannerImg from '../../img/Group 12909.png';
import { NAV_LINK_CONSTANTS } from '../Header/NavLinksContants';
import "./styles/communityBanner.css";
import { Link } from 'react-router-dom';

function CommunityBanner() {
  return (
    <div className="bannerContainer">
      <div className="bannerWrapper">
        <div className="imageWrapper">
          <img src={BannerImg} alt="banner-img" />
        </div>
        <div className="textWrapper">
          <h2 className="community-heading">Learn for Free</h2>
          <h2 className="community-heading noWrap">Crack Top Tech Companies</h2>
          <div className="tagsContainer">
            <div className="tag order3">Daily DSA Problem Solving</div>
            <div className="tag order1">Community Support</div>
            <div className="tag order2">Earning Cool Rewards</div>
          </div>
          <Link to={NAV_LINK_CONSTANTS.PUBLIC_COMMUNITY_URL}>
            <button className="joinButton desktopOnly">Join Now</button>
          </Link>
          <Link to={NAV_LINK_CONSTANTS.PUBLIC_COMMUNITY_URL}>
            <button className="joinButton mobileOnly">Join Our Community</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CommunityBanner;
