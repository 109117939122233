import React from "react";
import { useEffect } from "react";
import { availableCourses } from "../../courses/CourseConstants";
import { getCurrentCourse, setCurrentCourse } from "../../courses/CourseUtility";
import Header from "../Header/Header";
import Body from "../LandingPage/Body";
//import Footer from "../LandingPage/Footer";
import Footer from "../Footer/Footer";
// import BannerRibbon from "../Header/BannerRibbon";
import TfpRibbon from "../Header/TfpRibbon";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import Cookies from "universal-cookie";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import { Helmet } from "react-helmet-async";

export const LandingPageRoute = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.RENAISSANCE);
    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    return (
        <div className="">
            <Helmet>
                <title>Renaissance - Learn DSA, System Design & LLD with Programming Pathshala</title>
                <meta
                    name="description"
                    content="Ace coding interviews with DSA, System Design & LLD at Programming Pathshala. Learn from experts and gain the skills to clear tech interviews with confidence."
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/crack-coding-interviews" />
                <script type="application/ld+json">{
                `{
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "url": "https://renaissance.programmingpathshala.com/crack-coding-interviews",
                    "name": "Renaissance - Programming Pathshala",
                    "description": "Unlock your potential and ace coding interviews with the Crack Coding Interviews program by Programming Pathshala. Master DSA, algorithms, and interview strategies with expert guidance.",
                    "publisher": {
                        "@type": "Organization",
                        "name": "Programming Pathshala",
                        "url": "https://programmingpathshala.com",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "/ppa-icon-img.png"
                        }
                    },
                    "mainEntity": {
                        "@type": "EducationalOrganization",
                        "name": "Programming Pathshala",
                        "url": "https://programmingpathshala.com",
                        "sameAs": [
                            "https://www.facebook.com/programmingpathshala",
                            "https://www.linkedin.com/company/programmingpathshala"
                        ]
                    },
                    "offers": [
                        {
                            "@type": "Offer",
                            "price": "19999",
                            "priceCurrency": "INR",
                            "availability": "https://schema.org/InStock",
                            "url": "https://renaissance.programmingpathshala.com/signup"
                        }
                    ],
                    "hasCourse": {
                        "@type": "Course",
                        "name": "Renaissance Program",
                        "description": "Master DSA, System Design, and LLD with Programming Pathshala's Renaissance Program. Gain skills to ace coding and tech interviews.",
                        "provider": {
                            "@type": "Organization",
                            "name": "Programming Pathshala",
                            "sameAs": "https://renaissance.programmingpathshala.com/crack-coding-interviews"
                        },
                        "courseMode": "Online",
                        "educationalLevel": "Beginner to Advanced",
                        "offers": {
                            "@type": "Offer",
                            "category": "Paid",
                            "price": "19999",
                            "priceCurrency": "INR",
                            "availability": "https://schema.org/InStock",
                            "url": "https://renaissance.programmingpathshala.com/signup"
                        },
                        "hasCourseInstance": {
                            "@type": "CourseInstance",
                            "courseMode": "Online",
                            "name": "Renaissance Program - Coding Interview Prep",
                            "url": "https://renaissance.programmingpathshala.com/crack-coding-interviews",
                            "courseWorkload": "PT10H",
                            "courseSchedule": {
                                "@type": "Schedule",
                                "repeatFrequency": "Daily",
                                "repeatCount": "31"
                            }
                        }
                    }
                }
                `
                }
                </script>
                <meta property="og:title" content="Learn DSA, System Design & LLD with Programming Pathshala" />
                <meta property="og:description" content="Ace coding interviews with DSA, System Design, and LLD at Programming Pathshala. Master the skills to excel in tech interviews with confidence, guided by industry experts." />
                <meta property="og:url" content="https://renaissance.programmingpathshala.com/crack-coding-interviews" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://programmingpathshala.com/og-image.png" />
                <meta property="og:site_name" content="Programming Pathshala" />
            </Helmet>
            <div>{/* <BannerRibbon /> */}</div>
            <div className="header-sticky">
                <Header setCouponCode={setCouponCode}/>
            </div>
            <div>
                <Body
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    course={availableCourses.RENAISSANCE}
                />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    );
};
