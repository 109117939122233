import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import googleIcon from "../../../resources/images/google.svg";
import seePassword from "../../../resources/images/seePassword.svg";
import hidePassword from "../../../resources/images/hidePassword.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { loginPageValidationSchema } from "../../../Utilities/Validation";
import "./styles/Login.css";
import { loginWithEmailAndPassword } from "./LoginClient";
import { SIGNUP_AND_LOGIN_PAGE_STEPS, LOGGED_IN_FROM } from "../../../Constants/GlobalConstants";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import "./../../Common/styles/CommonFunction.css";
import Loader from "../../Loader/Loader";
import Header from "../../Header/Header";
import { Helmet } from "react-helmet-async";
import redirectToLandingPageStrategy from "../../../courses/Strategies/LandingPageRedirectionStrategy";
// import { useGetIsWhiteLabeledUser } from "../../../hooks/useGetIsWhiteLabeledUser";
import { openUniversityLoginPageWithPrefilledEmail } from "../../../Utilities/ReRoutingUtils";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export const Login = ({ setCouponCode }) => {
    let navigate = useNavigate();
    // const { getIsWhiteLabeledUser } = useGetIsWhiteLabeledUser();

    const [email, setEmail] = useState("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [password, setPassword] = useState("");
    const [formSubmitError, setFormSubmitError] = useState(false);
    const [formSubmitErrorMessage, setFormSubmitErrorMessage] = useState("");
    const [formSubmitLoaded, setFormSubmitLoaded] = useState(true);
    const [loginStep, setLoginStep] = useState(SIGNUP_AND_LOGIN_PAGE_STEPS.LOGIN);
    const { loggedIn, currentLoggedInUserEmail, setStatesAfterIsSubscribed } = useGlobalContext();

    const query = useQuery();

    useEffect(() => {
        if (query.get("page") === LOGGED_IN_FROM.MAIN_PPA)
            localStorage.setItem("isLoggedInFromMainPage", true);
    }, []);

    useEffect(() => {
        setEmail(currentLoggedInUserEmail);
    }, [currentLoggedInUserEmail]);

    const redirectUser = (link) => navigate(link);

    const redirectIfLoggedIn = () => {
        if (loggedIn) {
            // navigate('/crack-coding-interviews');
            redirectToLandingPageStrategy();
        }
    };

    const [emailAndPassword, setError] = useState("");

    const onSubmit = () => {
        mixpanel.track("Clicked on password login");
        loginPageValidationSchema
            .validate({ email: email, password: password })
            .then(async () => {
                setError("");
                setFormSubmitLoaded(false);
                setFormSubmitError(false);
                let isWhiteLabeledUser = false;
                let collegeId;
                // try {
                //     const { courses, collegeId: userCollegeId } = await getIsWhiteLabeledUser(
                //         email,
                //     );

                //     collegeId = userCollegeId;
                //     if (courses.includes("Response")) {
                //         //TODO: //CAUTION: handle for multiple course, currently Response is Renaissance
                //         isWhiteLabeledUser = true;
                //     }
                // } catch (err) {
                //     console.log(err.message);
                // }
                if (isWhiteLabeledUser) {
                    openUniversityLoginPageWithPrefilledEmail({ email, collegeId });
                } else {
                    loginWithEmailAndPassword(
                        email,
                        password,
                        setFormSubmitError,
                        setFormSubmitErrorMessage,
                        setFormSubmitLoaded,
                        setLoginStep,
                        redirectUser,
                        setStatesAfterIsSubscribed,
                        query.get("redirect_url"),
                    );
                }
            })
            .catch((err) => {
                setError(err.errors);
            });
    };

    const handleSignupClick = () => {
        mixpanel.track("Clicked on Dont have an account? Sign up");
        if (query.get("redirect_url")) {
            navigate(`/signup?redirect_url=${query.get("redirect_url")}`);
        } else {
            navigate("/signup");
        }
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
        }
    };

    const getLoginContent = () => {
        return (
            <section className="login-content-wrapper">
                {!formSubmitLoaded ? <Loader /> : <></>}
                <h1 className="common-page-title">Login</h1>
                <form className="login-content-form">
                    <label>
                        <p className="common-input-title">Email</p>
                        <input
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            onKeyDown={onKeyDown}
                            type="email"
                            required
                            className="common-input-styles example"
                            value={email}
                        />
                    </label>
                    <label>
                        <p className="common-input-title">Password</p>

                        <section className="login-content-password-wrapper">
                            {isPasswordVisible ? (
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="text"
                                    value={password}
                                    className="common-input-styles"
                                    onKeyDown={onKeyDown}
                                />
                            ) : (
                                <input
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    value={password}
                                    className="common-input-styles"
                                    onKeyDown={onKeyDown}
                                />
                            )}
                            {isPasswordVisible ? (
                                <img
                                    onClick={() => {
                                        mixpanel.track("Clicked on view password");
                                        setIsPasswordVisible(!isPasswordVisible);
                                    }}
                                    src={seePassword}
                                    alt=""
                                />
                            ) : (
                                <img
                                    onClick={() => {
                                        mixpanel.track("Clicked on view password");
                                        setIsPasswordVisible(!isPasswordVisible);
                                    }}
                                    src={hidePassword}
                                    alt=""
                                />
                            )}
                        </section>
                    </label>
                    <div>
                        {emailAndPassword && <p>{emailAndPassword}</p>}
                        {formSubmitErrorMessage && (
                            <p className="error-text">{formSubmitErrorMessage}</p>
                        )}
                    </div>
                    <p
                        className="forgot-password-text"
                        onClick={() => {
                            mixpanel.track("Clicked on Forget Password");
                            redirectUser("/forgot-password");
                        }}
                    >
                        Forgot password
                    </p>
                    <section>
                        <div onClick={onSubmit} className="common-blue-gradient-CTA">
                            Login
                        </div>
                        <p className="login-content-OR">OR</p>
                        <div
                            className="login-content-google-CTA"
                            onClick={() => {
                                mixpanel.track("Clicked on login with google");
                                if (query.get("redirect_url")) {
                                    window.open(
                                        process.env.REACT_APP_GOOGLE_OAUTH_LOGIN_URL +
                                            `&state=${query.get("redirect_url")}`,
                                        "_self",
                                    );
                                } else {
                                    window.open(
                                        process.env.REACT_APP_GOOGLE_OAUTH_LOGIN_URL,
                                        "_self",
                                    );
                                }
                            }}
                        >
                            <img src={googleIcon} alt="" />
                            <p>Login with Google</p>
                        </div>
                    </section>
                </form>
                <p onClick={handleSignupClick}>
                    Don't have an account? <span>Sign Up</span>
                </p>
            </section>
        );
    };

    return (
        <>
            <Helmet>
                <title>Login | Programming Pathshala</title>
                <meta
                    name="description"
                    content="Login to Programming Pathshala to access your courses."
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/login" />
            </Helmet>
            {redirectIfLoggedIn()}

            <div className="header-sticky">
                <Header setCouponCode={setCouponCode}/>
            </div>
            <section className="login-wrapper">{getLoginContent()}</section>
        </>
    );
};
