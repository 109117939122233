import React from "react";
import Slider from "react-slick";
import "./styles/YoutubeSection.css";
import youtubeImg1 from "../../img/youtube-img-1.png";
import youtubeImg2 from "../../img/youtube-img-2.png";
import youtubeImg3 from "../../img/youtube-img-3.png";
import youtubeImg4 from "../../img/youtube-img-4.png";
import youtubeImg5 from "../../img/youtube-img-5.png";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";

function YoutubeSection() {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow top={-115} right={0} />,
    prevArrow: <PrevArrow top={-115} right={15} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="youtube-section">
      <div className="youtube-section-container">
        <div className="youtube-header">
          <h2>Watch Free Videos on our YouTube Channel!</h2>
          <a href="https://www.youtube.com/@ProgrammingPathshala" target="_blank" rel="noopener noreferrer">
            <button className="youtube-btn">Visit our Youtube Channel</button>
          </a>
        </div>
        <div className="youtube-slider-container">
          <Slider {...settings}>
            <div className="youtube-slider-item">
              <a href="https://www.youtube.com/watch?v=5eu0PAX__Gc&list=PL8ALCSKTY-0trtGwo1v823v9VeJZTsb12&index=31" target="_blank" rel="noopener noreferrer">
                <img src={youtubeImg1} alt={`youtube-img`} className="youtube-img"/>
              </a>
            </div>
            <div className="youtube-slider-item">
              <a href="https://www.youtube.com/watch?v=0dP3nFlsXsY&list=PL8ALCSKTY-0trtGwo1v823v9VeJZTsb12&index=21" target="_blank" rel="noopener noreferrer">
                <img src={youtubeImg2} alt={`youtube-img`} className="youtube-img"/>
              </a>
            </div>
            <div className="youtube-slider-item">
              <a href="https://www.youtube.com/watch?v=Je4AuyryI5I&list=PL8ALCSKTY-0uLZeyhD35lv9FS0kVbMiGB&index=1" target="_blank" rel="noopener noreferrer">
                <img src={youtubeImg3} alt={`youtube-img`} className="youtube-img"/>
              </a>
            </div>
            <div className="youtube-slider-item">
              <a href="https://www.youtube.com/watch?v=8rmngl9sqrM&list=PL8ALCSKTY-0sAuIkcGZsO-ZL6JjzV0nqu" target="_blank" rel="noopener noreferrer">
                <img src={youtubeImg4} alt={`youtube-img`} className="youtube-img"/>
              </a>
            </div>
            <div className="youtube-slider-item">
              <a href="https://www.youtube.com/watch?v=5z0L6m1QKNg" target="_blank" rel="noopener noreferrer">
                <img src={youtubeImg5} alt={`youtube-img`} className="youtube-img"/>
              </a>
            </div>
          </Slider>
        </div>
        <a href="https://www.youtube.com/@ProgrammingPathshala" target="_blank" rel="noopener noreferrer">
          <button className="youtube-subscribe-btn">Subscribe to our Youtube Channel</button>
        </a>
      </div>
    </div>
  );
}

export default YoutubeSection;
