import React from "react";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { forgotPassword } from "./ForgotPasswordClient";
import {
    passwordValidationSchema,
    otpValidationSchema,
    emailValidationSchema,
} from "../../../Utilities/Validation";
import "./styles/ForgotPassword.css";
import { FORGOT_PASSWORD_SCREENS } from "../../../Constants/GlobalConstants";
import OtpInput from "react-otp-input";
import { confirmPassword } from "../../SetPassword/SetPasswordClient";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import "./../../Common/styles/CommonFunction.css";
import Loader from "../../Loader/Loader";
import Header from "../../Header/Header";
import PasswordValidatePopup from "../PasswordValidatePopup/PasswordValidatePopup";
import { Formik, Form } from "formik";
import { PasswordField } from "../../Common/PasswordField";
import redirectToLandingPageStrategy from "../../../courses/Strategies/LandingPageRedirectionStrategy";
import { Helmet } from "react-helmet-async";

export const ForgotPassword = ({ setCouponCode }) => {
    let navigate = useNavigate();
    const [otpFlag, setOtpSent] = useState(false);
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const [forgotPasswordError, setForgotPasswordError] = useState(false);
    const [otpError, setOtpError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [confirmPwdSubmitLoading, setConfirmPwdSubmitLoading] = useState(false);
    const [confirmPwdSubmitError, setConfirmPwdSubmitError] = useState(false);
    const [confirmPwdSubmitErrorMessage, setConfirmPwdSubmitErrorMessage] = useState("");
    const [confirmPwdSubmitSuccess, setConfirmPwdSubmitSuccess] = useState(false);
    const [confirmPwdSubmitSuccessMessage, setConfirmPwdSubmitSuccessMessage] = useState("");
    const [forgotPasswordErrorMessage, setForgotPasswordErrorMessage] = useState("");
    const [counter, setCounter] = useState(45);
    const [forgotPasswordScreen, setforgotPasswordScreen] = useState(
        FORGOT_PASSWORD_SCREENS.FORGOT_PASSWORD
    );
    const { loggedIn } = useGlobalContext();

    const redirectUser = (link) => navigate(link);
    const handleOtpChange = (otp) => setOtp(otp);

    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const redirectIfLoggedIn = () => {
        // if (loggedIn) navigate("/crack-coding-interviews");
        if (loggedIn) redirectToLandingPageStrategy();
    };

    const resetCounter = function () {
        if (!counter) {
            setCounter(30);
        }
        forgotPassword(
            email,
            setOtpSent,
            setLoading,
            setForgotPasswordError,
            setForgotPasswordErrorMessage,
            setforgotPasswordScreen
        );
    };

    const onEmailSubmit = () => {
        emailValidationSchema
            .validate({ email: email })
            .then(() => {
                setLoading(true);
                setForgotPasswordError(false);
                setForgotPasswordErrorMessage("");
                forgotPassword(
                    email,
                    setOtpSent,
                    setLoading,
                    setForgotPasswordError,
                    setForgotPasswordErrorMessage,
                    setforgotPasswordScreen
                );
            })
            .catch((err) => {
                setEmailError(err.errors);
            });
    };

    const onOtpAndPasswordSubmit = (password) => {
        if (confirmPwdSubmitSuccess) redirectUser("/login");
        else
            otpValidationSchema
                .validate({ otp: otp })
                .then(() => {
                    setConfirmPwdSubmitLoading(true);
                    confirmPassword(
                        email,
                        otp,
                        password,
                        setConfirmPwdSubmitLoading,
                        setConfirmPwdSubmitError,
                        setConfirmPwdSubmitErrorMessage,
                        setConfirmPwdSubmitSuccess,
                        setConfirmPwdSubmitSuccessMessage
                    );
                    setOtpError("");
                })
                .catch((err) => {
                    setOtpError(err.errors);
                });
    };

    const getForgotPasswordInitiateSection = () => {
        return (
            <section className="common-form-wrapper">
                {loading && <Loader />}
                <h1 className="common-page-title ">Forgot Password</h1>
                <p className="common-input-title">Email</p>
                <input
                    className=" forgot-password-input common-input-styles"
                    type="text"
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                ></input>
                {forgotPasswordErrorMessage ? (
                    <p className="error-text">{forgotPasswordErrorMessage}</p>
                ) : (
                    <></>
                )}
                <div>
                    <p className="error-text">{emailError ? emailError : ""}</p>
                </div>
                <div
                    className=" forgot-password-CTA common-blue-gradient-CTA"
                    onClick={() => {
                        mixpanel.track("Clicked on forget password");
                        onEmailSubmit();
                    }}
                >
                    Forgot Password
                </div>
                <p></p>
                <div className="login-content-google-CTA">
                    <p
                        onClick={() => {
                            redirectUser("/login");
                        }}
                    >
                        Login
                    </p>
                </div>
                <p className="signup-setup-login">
                    Don't have an account?{" "}
                    <span
                        className="signup-text"
                        onClick={() => {
                            redirectUser("/signup");
                        }}
                    >
                        Sign Up
                    </span>
                </p>
            </section>
        );
    };

    const setNewPassword = () => {
        return (
            <Formik
                initialValues={{
                    password: "",
                    confirmPassword: "",
                }}
                validationSchema={passwordValidationSchema}
                onSubmit={(values) => {
                    onOtpAndPasswordSubmit(values.password);
                }}
            >
                {(formik) => (
                    <Form style={{ width: "100%" }}>
                        <div style={{ position: "relative" }}>
                            <PasswordField label="Set Password" name="password" />
                            <div className="password-validate-popup">
                                <PasswordValidatePopup password={formik.values.password} />
                            </div>
                        </div>
                        <PasswordField label="Confirm Password" name="confirmPassword" />

                        {confirmPwdSubmitSuccess && (
                            <p className="success-text">{confirmPwdSubmitSuccessMessage}</p>
                        )}
                        <button
                            className="signup-otp-confirm-CTA common-blue-gradient-CTA"
                            type="submit"
                        >
                            {confirmPwdSubmitSuccess ? "Click Here to Login" : "Change Password"}
                        </button>
                    </Form>
                )}
            </Formik>
        );
    };

    const createNewPassword = () => {
        return (
            <section className="forgot-pwd-otp-set-up-wrapper">
                {confirmPwdSubmitLoading && <Loader />}
                <h1 className="common-page-title ">Change Password</h1>
                <p className="otp-sent-heading">OTP has been sent to {email}</p>
                <OtpInput
                    value={otp}
                    className="signup-otp-input-wrapper"
                    onChange={handleOtpChange}
                    numInputs={6}
                />
                {otpError ? (
                    <p className="error-text">{otpError}</p>
                ) : confirmPwdSubmitError ? (
                    <p className="error-text">{confirmPwdSubmitErrorMessage}</p>
                ) : (
                    <></>
                )}

                <p className="signup-otp-password-timer">
                    <span onClick={resetCounter}>Resend OTP</span> in : 00:{counter}
                </p>
                <p className="signup-otp-password-note">
                    Note: The email may be in your spam folder
                </p>

                {setNewPassword()}
            </section>
        );
    };

    return forgotPasswordScreen === FORGOT_PASSWORD_SCREENS.FORGOT_PASSWORD ? (
        <>
            <Helmet>
                <title>Forgot Password</title>  
                <meta name="description" content="Reset your password" />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/forgot-password" />
            </Helmet>
            {redirectIfLoggedIn()}
            <div className="header-sticky">
                <Header setCouponCode={setCouponCode}/>
            </div>
            <section className="forgot-password-container">
                {getForgotPasswordInitiateSection()}
            </section>
        </>
    ) : forgotPasswordScreen == FORGOT_PASSWORD_SCREENS.CREATE_NEW_PASSWORD ? (
        <>
            {redirectIfLoggedIn()}
            <div className="header-sticky">
                <Header />
            </div>
            <section className="forgot-password-container">{createNewPassword()}</section>
        </>
    ) : (
        <></>
    );
};
