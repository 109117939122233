import React from "react";
import dropDownCloseIcon from "../../img/dropDownCloseIcon.svg";
import dropDownOpenIcon from "../../img/dropDownOpenIcon.svg";
import "./Styles/faq.css";

const FaqAccordion = (props) => {
    const handleAccordionClick = () => {
        props.recordAccordionId(props.identifier);
    };

    return (
        <div onClick={handleAccordionClick} className="cpp-accordion-wrapper">
            <div className={(props.isOpen ? "cpp-accordion-background" : " ") + " cpp-accordion-item"}>
                {" "}
                <div className="cpp-accordion-question">
                    <h3>{props.accordionData.question}</h3>
                    {props.isOpen ? <img src={dropDownCloseIcon} alt="" /> : <img src={dropDownOpenIcon} alt="" />}
                </div>
                <div className={(props.isOpen ? "cpp-accodrion-show" : " ") + " cpp-accodrion-content"}>
                    <p>{props.accordionData.answer}</p>
                </div>
            </div>
        </div>
    );
};

export default FaqAccordion;
