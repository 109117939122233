import lecturesImg from "../../../img/python-lectures.png";
import problemsImg from "../../../img/python-problems.png";
import doubtSupportImg from "../../../img/python-doubt-support.png";
import regularLiveClasses from "../../../img/python-live-class.png";
import beginnerFriendlyImg from "../../../img/python-solid-brain.png";
import mockInterviewImg from "../../../img/python-interview.png";

const PythonAboutContents = {
    sectionSubHeading: <p className="customHeading">Unlock your potential:  Embrace Python for endless possibilities!</p>,
    sectionDescription: (
        <p>
            Python has a relatively simple syntax- which makes it a good starting point for complete beginners. <strong>Python is the most sought after language</strong> to become a <strong>Machine Learning Engineer</strong> or a <strong>Data Scientist,</strong> or for <strong>DSA & Problem Solving</strong> preparation for Coding Interviews. Even if you want to become a <strong>Web Developer</strong>, learning python opens up the gate to learn <strong>Django</strong> and <strong>Flask Backend Development Frameworks</strong>. So why wait? Embrace Python for endless possibilities!
        </p>
    ),
    AboutInfoWithImages: [
        {
            image: lecturesImg,
            desc: <span><strong>150+</strong> In-depth lectures</span>,
        },
        {
            image: problemsImg,
            desc: <span><strong>100+</strong> Problems covered</span>,
        },
        {
            image: doubtSupportImg,
            desc: <span>Real time Doubt Resolution by course mentors.</span>,
        },
        {
            image: beginnerFriendlyImg,
            desc: <span>Develop Problem solving ability</span>,
        },
        {
            image: mockInterviewImg,
            desc: <span>Start with Competitive Programming & Interview Preparation</span>,
        },
      
    ],
};

export { PythonAboutContents };
