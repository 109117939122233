import React from "react";
import bannerImg from "../../../img/TermsAndConditionBanner.png";
import "./TermsBanner.css";

const getTermsBannerContent = () => {
    return (
        <div className="terms-banner-content-wrapper">
            <h1>Terms and Conditions</h1>
            <p>
                Learn more about how Programming Pathshala collects and uses data and your rights as
                a Programming Pathshala user.
            </p>
        </div>
    );
};
const getTermsBannerImg = () => {
    return (
        <div className="terms-banner-img-wrapper">
            <img src={bannerImg} alt="section-banner" />
        </div>
    );
};
const TermsBanner = () => {
    return (
        <div className="terms-banner-wrapper">
            {getTermsBannerContent()}
            {getTermsBannerImg()}
        </div>
    );
};

export default TermsBanner;
