import React from "react";
import "./styles/Mentor.css";
import Vivek from "../../img/Vivek.webp";
import Likhilesh from "../../img/Likhilesh.webp";
import ProgramingPathshala from "../../img/PPA Logo.svg";
import Amazon from "../../img/Amazon_logo.svg";
import linkedinImg from "../../img/Linkedin.svg";
import IDQ from "../../img/Cogoport.svg";
import underLine from  "../../img/Vector 55.svg";

const mentors = [
    {
        name: "Vivekanand Vivek",
        role: "IIT BHU Alumni, Ex-SDE",
        companyLogo: Amazon,
        position: "Co-Founder",
        company: ProgramingPathshala,
        linkedin: linkedinImg,
        image: Vivek,
        description: <div className="mentor-desc">Vivek has taught <strong>10,000+ students</strong> through on campus workshops and online courses on Interview Preparation. <strong>He has worked at Amazon with its High Scale Systems for three years after graduating from IIT.</strong> He is loved by his students for his lucid in-depth explanations and ability to make people think through problems.</div>
    },
    {
        name: "Likhilesh Balpande",
        role: "IIT Dharwad Alumni, Ex-SDE",
        companyLogo: IDQ,
        position: "Technical Lead",
        company: ProgramingPathshala,
        linkedin: linkedinImg,
        image: Likhilesh,
        description: <div className="mentor-desc">Likhilesh has mentored <strong>500+ students</strong>, simplifying complex concepts with a hands-on approach. Loved for his clear explanations, he helps learners build strong problem-solving skills. <strong>With experience as an SDE at Cogoport and an SDE Intern at Gupshup Technologies</strong>, he brings real-world insights to his teaching.</div>
    },
];

const Mentor = () => {
    
    return (
        <div className="mentor-container">
            <div className="mentor-section-heading">
                <h2 className="mentor-title">Learn with Industry Experienced Mentor</h2>
                <img src={underLine} alt="" className="underline" />
            </div>
            <div className="mentors-wrapper">
                {mentors.map((mentor, index) => (
                    <div key={index} className="mentor-content">
                        <div className="mentor-header">
                            <img src={mentor.image} alt="Mentor Pic" className="mentor-image"/>
                            <div className="mentor-detail">
                                <div className="mentor-naming">{mentor.name}</div>
                                <div className="mentor-experience">
                                    <div className="mentor-role">{mentor.role}</div>
                                    <img src={mentor.companyLogo} alt="Amazon" className="company-logo"/>
                                </div>
                                <div className="mentor-company">
                                    <div className="mentor-role">{mentor.position}</div>
                                    <img src={mentor.company} alt="Programming Pathshala" className="ppa_logo"/>
                                </div>
                                <img src={mentor.linkedin} alt="LinkedIn" className="linkedin-logo"/>
                            </div>
                        </div>
                        {mentor.description}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Mentor;
