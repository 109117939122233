import React from "react";
import {
    privatePolicyData,
    rightsAndObligationsData,
} from "../../../Constants/TermsAndConditionsConstant";
import "./PrivacyPolicy.css";
const getPrivatePolicyContents = () => {
    return (
        <>
            {privatePolicyData &&
                privatePolicyData.map((data, index) => (
                    <div className="policy-contents-wrapper" key={index}>
                        <h3>{data.title}</h3>
                        <p>{data.content}</p>
                    </div>
                ))}
        </>
    );
};
const getAboutPaymentContent = () => {
    return (
        <>
            <h3>Payment</h3>
            <p>
                Unless otherwise expressly agreed in writing by Programming Pathshala, full payment
                for enrolment and participation in a Program is required at the time of
                registration. To make payment for any Program or to purchase any services or
                products offered by Programming Pathshala through the Platform, you must have
                internet access and a current valid accepted payment method as indicated on the
                Platform (“Payment Method”). Programming Pathshala does not store any of your credit
                card/debit Card information or such other information restricted by the Reserve Bank
                of India (RBI) for processing payment and has partnered with payment gateways for
                the payment towards the services.
            </p>
        </>
    );
};

const getRightsAndObligarions = () => {
    return (
        <div className="right-and-obligation-wrapper">
            <h2>Rights and Obligations relating to the Content</h2>
            <h3 className="right-and-obligation-wrapper-subtitle">You are prohibited from:</h3>
            {rightsAndObligationsData &&
                rightsAndObligationsData.map((data, index) => (
                    <p key={index}>
                        {"\u2022"} {data.content}
                    </p>
                ))}
        </div>
    );
};

const PrivacyPolicy = () => {
    return (
        <div className="policy-wrapper">
            <h2>Privacy Policy </h2>
            {getPrivatePolicyContents()}
            {getRightsAndObligarions()}
            {getAboutPaymentContent()}
        </div>
    );
};

export default PrivacyPolicy;
