import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogsCard from "./BlogsCard";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import { NAV_LINK_CONSTANTS } from "../Header/NavLinksContants";
import { Link } from "react-router-dom";
import BlogImage1 from "../../img/blog-image-1.png";
import BlogImage2 from "../../img/blog-image-2.png";
import BlogImage3 from "../../img/blog-image-3.png";
import BlogProfile from "../../img/blog-profile-pic.png";
import "./styles/BlogsSection.css";

const BlogsData=[
    {
        id:1,
        blogImg:BlogImage1,
        blogHead:"Sliding Window",
        blogPara:`When working with arrays, problems often involve looking at groups of elements, or "windows,"...`,
        blogProfile:BlogProfile,
        blogAuthor:"Programming Pathshala",
        blogTimeline:["September 21, 2024","8 Minutes"],
        blogLink:`${NAV_LINK_CONSTANTS.PUBLIC_BLOGS_URL}sliding-window-minimum-number-of-swaps-to-group-all-elements-together/99`
    },
    {
        id:2,
        blogImg:BlogImage2,
        blogHead:"Sieve of Eratosthenes",
        blogPara:"Time Complexity: O(KNlogK), where N is the total number of nodes across all lists. This complexity...",
        blogProfile:BlogProfile,
        blogAuthor:"Programming Pathshala",
        blogTimeline:["September 21, 2024","8 Minutes"],
        blogLink:`${NAV_LINK_CONSTANTS.PUBLIC_BLOGS_URL}sieve-of-eratosthenes/107`
    },
    {
        id:3,
        blogImg:BlogImage3,
        blogHead:"Intro to JDK, JRE, and JVM",
        blogPara:"I never thought I'd join Google because I already had my offer from Oracle, so I had the mindset...",
        blogProfile:BlogProfile,
        blogAuthor:"Programming Pathshala",
        blogTimeline:["September 21, 2024","8 Minutes"],
        blogLink:`${NAV_LINK_CONSTANTS.PUBLIC_BLOGS_URL}jdk-jre-jvm/95`
    },
]

function BlogsSection() {
  let settings2 = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: <NextArrow top={-92} right={0} />,
    prevArrow: <PrevArrow top={-92} right={15} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true, dots: false },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 480,
        settings: { arrows: true, slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  return (
    <div className="blogs-section">
      <div className="blogs-header">
        <h2 className="blogs-title">
          Your Go-To Blog for Tech, Career Growth, and Learning!
        </h2>
        <a href={NAV_LINK_CONSTANTS.PUBLIC_BLOGS_URL} className="read-more-btn-desktop">
          <button className="read-more-btn">Read More Blogs</button>
        </a>
      </div>

      {/* Blog Cards for larger screens */}
      <div className="blogs-grid">
        {BlogsData.map((blogdata) => (
          <a key={blogdata.id} href={blogdata.blogLink}>
            <BlogsCard {...blogdata} />
          </a>
        ))}
      </div>

      {/* Slider for smaller screens */}
      <div className="blogs-slider">
        <Slider {...settings2}>
          {BlogsData.map((blogdata) => (
            <a key={blogdata.id} href={blogdata.blogLink}>
              <BlogsCard {...blogdata} />
            </a>
          ))}
        </Slider>
      </div>

      {/* Button for smaller screens */}
      <div className="blogs-footer">
        <a href={NAV_LINK_CONSTANTS.PUBLIC_BLOGS_URL}>
          <button className="read-more-btn">Read More Blogs</button>
        </a>
      </div>
    </div>
  );
}

export default BlogsSection;
