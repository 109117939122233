import React from "react";
import { useState } from "react";
import { CppFaqContents } from "./Content/CppFaqsContent";
import "./Styles/faq.css";
import FaqAccordion from "./FaqAccordion";

const Faq = () => {
    const [openId, setOpenId] = useState(1);
    const recordAccordionId = (id) => {
        if (id === openId) setOpenId(-1);
        else setOpenId(id);
    };
    return (
        <div className="cpp-faq-wrapper">
            <h2>
                Frequently <strong>Asked Questions</strong>
            </h2>
            {CppFaqContents &&
                CppFaqContents.map((accordionData, index) => (
                    <div key={index}>
                        <FaqAccordion
                            identifier={accordionData.id}
                            key={index}
                            accordionData={accordionData}
                            recordAccordionId={recordAccordionId}
                            isOpen={accordionData.id === openId}
                        />
                    </div>
                ))}
        </div>
    );
};

export default Faq;
