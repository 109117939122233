import React from "react";
import scholarshipImg from "../../img/cpp-scholarship.png";
import "./styles/Scholarship.css";

const Scholarship = () => {
  return (
    <div className="dsa-scholarship-wrapper">
      <div className="dsa-scholarship-image-wrapper">
        <img src={scholarshipImg} alt="Get upto 100% scholarships in DSA and other coding courses." />
      </div>
      <div className="dsa-scholarship-content-wrapper">
        <div className="dsa-scholarship-about">
          <span>
            Up-to{" "}
            <span className="dsa-scholarship-text">100% scholarships</span> for
            students in need.{" "}
          </span>
          <br className="line-break" />
          <span>
            <span className="dsa-scholarship-text">Avail now</span> and achieve
            your dreams.
          </span>
        </div>
        <div className="dsa-scholarship-button-wrapper">
          <a
            className="dsa-scholarship-button"
            href="https://docs.google.com/forms/d/1aob-tQJcuFCtL8H3I8zOtl6zkjVcWB6sfJ3OzuLXcqc/viewform?edit_requested=true"
          > Apply Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Scholarship;
