import React, { useState } from "react";
import { handleApplyCouponCode } from "../../Utilities/BannerUtils";
import copy from "../../img/Vector (8).svg";
import "./styles/AdvertisementBanner.css";
import RightImage from "../../img/Right light.svg";
import LeftImage from "../../img/left light.svg";
import MobileImage from "../../img/Group (4).svg";

const AdvertisementBanner = ({ showClaimNow, setCouponCode }) => {
    const editableBanner = {
        id: "2f48bd6e-12c6-4a62-9db6-fc6a271cda46",
        bannerName: "Navratri Special",
        discountPercentage: "40% OFF",
        discountCode: "NAVRATRI40",
        startDate: "2025-04-02",
        end_date: "2025-04-10",
        status: "ACTIVE",
        promoText: "Navratri Special: Get ",
        applyText: ". Apply Code: ",
        desktopLeftImage: LeftImage,
        desktopRightImage: RightImage,
        mobileLeftImage: MobileImage,
        styles: {
            bannerContent: {
                color: "#FFFFFF",
            },
            claimNowButton: {
                color: "#000000",
                background: "#FFFFFF",
            },
            offerHighlight: {
                color: "#FBBF24",
                textDecoration: "underline",
            },
            bannerContainer: {
                background: "radial-gradient(50% 50% at 50% 50%, #E7000B 0%, #82181A 100%)",
            },
            desktopLeftImage: {
                position: "absolute",
                left: "0",
                top: "0%",
                width: "auto",
                height: "90%",
            },
            desktopRightImage: {
                position: "absolute",
                right: "0",
                top: "0",
                width: "auto",
                height: "90%",
            },
            mobileLeftImage: {
                position: "absolute",
                left: "2%",
                top: "25%",
                width: "auto",
                height: "40%",
            },
            bannerContentContainer: {
                marginLeft: "35px",
            }
        },
    };
    const [copied, setCopied] = useState(false);

    const handleCopyCode = () => {
        navigator.clipboard.writeText(editableBanner?.discountCode).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    return (
        <div className="banner-container" style={{ ...editableBanner?.styles?.bannerContainer }}>
            <img
                src={editableBanner?.mobileLeftImage || ""}
                alt={editableBanner?.discountCode || ""}
                className="mobile-left-image"
                style={{ ...editableBanner?.styles?.mobileLeftImage }}
            />
            <img
                src={editableBanner?.desktopLeftImage || ""}
                alt={editableBanner?.discountCode || ""}
                className="left-image"
                style={{ ...editableBanner?.styles?.desktopLeftImage }}
            />
            <div className="banner-content-container"
                style={{ ...editableBanner?.styles?.bannerContentContainer }}
            >
                <div
                    className="banner-content"
                    style={{ ...editableBanner?.styles?.bannerContent }}
                >
                    {editableBanner.promoText}
                    <span
                        className="offer-highlight"
                        style={{ ...editableBanner?.styles?.offerHighlight }}
                    >
                        {`${editableBanner?.discountPercentage}`}
                    </span>
                    {editableBanner.applyText}
                    <span
                        className="offer-highlight"
                        style={{ ...editableBanner?.styles?.offerHighlight }}
                    >
                        {`${editableBanner?.discountCode}`}
                    </span>
                </div>
                {showClaimNow && (
                    <button
                        onClick={() =>
                            handleApplyCouponCode({
                                couponCode: editableBanner?.discountCode,
                                setCouponCode,
                            })
                        }
                        className="claim-button"
                        style={{
                            ...editableBanner?.styles?.claimNowButton,
                        }}
                    >
                        Claim now
                    </button>
                )}
                {!showClaimNow && (
                    <button
                        onClick={handleCopyCode}
                        className="claim-button"
                        style={{ ...editableBanner?.styles?.claimNowButton }}
                    >
                        {copied ? (
                            "Copied"
                        ) : (
                            <>
                                <img src={copy} alt="copy code icon" className="copy-icon" /> Copy
                                code
                            </>
                        )}
                    </button>
                )}
            </div>
            <img
                src={editableBanner.desktopRightImage || ""}
                alt="Right Banner"
                className="right-image"
                style={{ ...editableBanner?.styles?.desktopRightImage }}
            />
        </div>
    );
};

export default AdvertisementBanner;
