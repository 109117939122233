import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Cookies from "universal-cookie";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import { setUTMParametersInLocalStorage, useQuery } from "../../Utilities/CommonUtilities";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";

import Header from "../Header/Header";
import IntroDesktop from "./IntroDesktop";
import LogoBelt from "./LogoBelt";
import Impact from "./Impact";
import WhyChoose from "./WhyChoose";
import TargetAudience from "./TargetAudience";
import Outcomes from "./Outcomes";
import CourseCurriculum from "./CourseCurriculum";
import ProjectSection from "./ProjectSection";
import Mentor from "./Mentor";
import SuccessStories from "../CppLandingPage/SuccessStories";
import Pricing from "../CppLandingPage/Pricing/Pricing";
import Faq from "./Faq";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "../Common/CommonComponents/FreeTrial";
import Footer from "../Footer/Footer";

import "./styles/SpringBootPage.css";
import CommunityBanner from "./CommunityBanner";
import YoutubeSection  from "./YoutubeSection";
import BlogsSection from "./BlogsSection";

function SpringBootLandingPage({ couponCode, setCouponCode }) {
    setCurrentCourse(availableCourses.JAVA_SPRINGBOOT);
    
    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();

    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) {
            checkSubscribed();
        }
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);

    return (
        <div className="springboot-page">
            <Helmet>
                <title>Build Expertise in Backend Engineering with JAVA Spring Boot</title>
                <meta
                    name="description"
                    content="Learn Java Spring Boot and backend engineering with hands-on projects. Master Spring MVC, Hibernate and security. Enroll in our expert-led course!"
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/java-springboot" />
                
                <script type="application/ld+json">
                  {`
                    { 
                        "@context": "https://schema.org", 
                        "@type": "WebPage", 
                        "url": "https://renaissance.programmingpathshala.com/java-springboot", 
                        "name": "Java Spring Boot - Renaissance by Programming Pathshala", 
                        "description": "Master Backend Engineering with our Java Spring Boot course. Learn Spring MVC, Security, Hibernate, and more. Build real-world projects and ace backend developer interviews.", 
                        "publisher": { 
                            "@type": "Organization", 
                            "name": "Programming Pathshala", 
                            "url": "https://renaissance.programmingpathshala.com", 
                            "logo": { "@type": "ImageObject", "url": "/ppa-icon-img.png" } 
                        }, 
                        "mainEntity": { 
                            "@type": "EducationalOrganization", 
                            "name": "Programming Pathshala", 
                            "url": "https://renaissance.programmingpathshala.com", 
                            "sameAs": [
                                "https://www.facebook.com/programmingpathshala",
                                "https://www.linkedin.com/company/programming-pathshala",
                                "https://www.instagram.com/programmingpathshala/"
                            ]
                        }, 
                        "hasCourse": { 
                            "@type": "Course", 
                            "name": "Java Spring Boot - Renaissance Program", 
                            "description": "Learn Java Spring Boot framework, backend development, Spring Security, Hibernate, JPA, and real-world project implementation in this expert-led course.", 
                            "provider": { 
                                "@type": "Organization", 
                                "name": "Programming Pathshala", 
                                "sameAs": "https://renaissance.programmingpathshala.com/java-springboot" 
                            }, 
                            "courseMode": "Online", 
                            "educationalLevel": "Beginner to Advanced", 
                            "educationalCredentialAwarded": "Certificate of Completion", 
                            "offers": { 
                                "@type": "Offer", 
                                "category": "Paid", 
                                "price": "11999", 
                                "priceCurrency": "INR", 
                                "availability": "https://schema.org/InStock", 
                                "url": "https://renaissance.programmingpathshala.com/signup" 
                            }, 
                            "hasCourseInstance": { 
                                "@type": "CourseInstance", 
                                "courseMode": "Online", 
                                "courseWorkload": "PT10H", 
                                "courseSchedule": { "@type": "Schedule", "repeatFrequency": "Daily", "repeatCount": "31" }, 
                                "instructor": { "@type": "Person", "name": "Vivekanand Vivek" }, 
                                "location": { "@type": "VirtualLocation", "url": "https://renaissance.programmingpathshala.com/java-springboot" } 
                            } 
                        } 
                    }
                  `}
                </script>
                <meta property="og:title" content="Master Backend Engineering in JAVA Spring Boot Framework"/>
                <meta property="og:description" content="Master Backend Engineering with our JAVA Spring Boot course. Learn Spring MVC, Security, Hibernate, and more. Build real-world projects and ace backend developer interviews."/>
                <meta property="og:url" content="https://renaissance.programmingpathshala.com/java-springboot"/> 
                <meta property="og:type" content="website"/> 
                <meta property="og:image" content="/ppa-icon-img.png"/>
            </Helmet>
            <div className="header-sticky">
                <Header setCouponCode={setCouponCode}/>
            </div>
            <IntroDesktop />
            <LogoBelt />
            <Impact />
            <WhyChoose />
            <TargetAudience />
            <Outcomes />
            <CourseCurriculum />
            <ProjectSection />
            <Mentor />
            <SuccessStories />
            <Pricing couponCode={couponCode} setCouponCode={setCouponCode} />
            <Faq />
            <CommunityBanner />
            <YoutubeSection />
            <BlogsSection />
            {/* <ExploreCourses course={availableCourses.CRASH_COURSE} /> */}
            {/* <ExploreBlogs course={availableCourses.CRASH_COURSE} /> */}
            {/* <FreeTrial /> */}
            <Footer />
        </div>
    );
}

export default SpringBootLandingPage;
