import dataScienceIcon from "../../../img/python-datascience-icon.png";
import machineLearningicon from "../../../img/python-ml-icon.png";
import dsaIcon from "../../../img/python-dsa-icon.png";
import programmingIcon from "../../../img/python-programming-icon.png";
import webBackendIcon from "../../../img/python-web-backend-icon.png";
import competitiveIcon from "../../../img/python-competition-icon.png";

const PythonAudienceContents = {
    sectionTitle: <h2>Who this course is for?</h2>,
    sectionSubTitle: <p>This course is for anyone who wants to get started with their journey in:</p>,
    sectionOfferings: [
        {
            text: "Data Science",
            img: dataScienceIcon,
        },
        {
            text: "Machine Learning and Artificial Intelligence",
            img: machineLearningicon,
        },
        {
            text: "Data Structures and Algorithms",
            img: dsaIcon,
        },
        {
            text: "Programming",
            img: programmingIcon,
        },
        {
            text: "Web/ Backend Development",
            img: webBackendIcon,
        },       
       
        {
            text: "Competitive Programming",
            img: competitiveIcon,
        },
    ],
};

export { PythonAudienceContents };
