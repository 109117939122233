import React from "react";
import { useState } from "react";
import "./styles/Curriculum.css";
import { PythonTopics } from "./Content/PythonCurriculumContent";
import PythonCurriculumPdf from "../../CurriculumPdf/PythonCurriculum.pdf";
import downloadIcon from "../../img/download-btn-ren.png";
import dropDownCloseIcon from "../../img/dropDownCloseIcon.svg";
import dropDownOpenIcon from "../../img/dropDownOpenIcon.svg";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

const Curriculum = () => {
    const [showMorePython, setShowMorePython] = useState(false);
    const curriculumContent = PythonTopics;

    const toggleReadMoreLessPython = () => {
        setShowMorePython(!showMorePython);
    };

    return (
        <div className="python-curriculum-container">
            <h2>
                Course <strong>Curriculum</strong>
            </h2>
            <div className="python-curriculum-card-wrapper">
                {curriculumContent &&
                    curriculumContent.map((curriculum, index) => (
                        <>
                            {index < 5 ? (
                                <div className="python-curriculum-card">
                                    <h3>{curriculum.heading}</h3>
                                    {curriculum.data &&
                                        curriculum.data.map((data) => <h4>{data}</h4>)}
                                </div>
                            ) : showMorePython ? (
                                <div className="python-curriculum-card">
                                    <h3>{curriculum.heading}</h3>
                                    {curriculum.data &&
                                        curriculum.data.map((data) => <h4>{data}</h4>)}
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    ))}
                <div className="python-show-more-wrapper" onClick={toggleReadMoreLessPython}>
                    <p className="python-show-more-text">
                        {!showMorePython ? (
                            <>
                                <span>Show More</span>
                                <img src={dropDownOpenIcon} alt="" />
                            </>
                        ) : (
                            <>
                                <span>Show Less</span>
                                <img src={dropDownCloseIcon} alt="" />
                            </>
                        )}
                    </p>
                </div>
            </div>

            <a
                className={GTM_TRACKER.DOWNLOAD_CURRICULUM}
                href={PythonCurriculumPdf}
                rel="noopener noreferrer"
                target="_blank"
            >
                <div className="python-download-curriculum">
                    <img src={downloadIcon} alt="" />
                    <p>Download Detailed Curriculum</p>
                </div>
            </a>
        </div>
    );
};

export default Curriculum;
