import DsaCore from "../../../img/dsa-core.png";
import DsaAlgo from "../../../img/dsa-algo.png";
import DsaTimeSpace from "../../../img/dsa-time-space.png";
import DsaPractice from "../../../img/dsa-practice.png";


const DsaAdvantagesContent = {
    sectionTitle: <h2>Get the Programming Pathshala Advantage</h2>,
    sectionDescription: (
        <p>
            Your Learning Outcomes will be:
        </p>
    ),
    AdvantagesList: [
        {
            type: "Understand core DSA concepts well",
            text: "Develop a deep understanding of the most in-demand DSA concepts and techniques asked in interviews today.",
            bgColor: "#E8EDFB",
            img: DsaCore,
        },
        {
            type: "Optimise problem solving approach",
            text: "Develop an ability to analyze the time and space complexity, and solve from brute force to most optimised approach.",
            bgColor: "#ECFDF5",
            img: DsaAlgo,
        },
        {
            type: "Practice the most asked DSA questions in interviews",
            text: "Improve problem-solving skills and understand how to approach and solve questions from concepts commonly asked in DSA interviews.",
            bgColor: "#FFFBEB",
            img: DsaTimeSpace,
        },
        {
            type: "Get Job Ready",
            text: "Ace DSA rounds of Software Engineering interviews.",
            bgColor: "#FEF2F2",
            img: DsaPractice,
        },
    ],
};

export { DsaAdvantagesContent };
