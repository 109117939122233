import React from "react";
import "./styles/WhyChoose.css";
import { CardDataWhyChoose as CardData } from "./SpringBootData";

const Card = ({ icon, heading, paragraph }) => {
    return (
        <div className="springboot-whychoose-card">
            <img className="springboot-whychoose-card-icon" src={icon} alt={`${icon}`} />
            <p className="springboot-whychoose-heading">{heading}</p>
            <p className="springboot-whychoose-paragraph">{paragraph}</p>
        </div>
    );
};

const WhyChoose = () => {
    return (
        <div className="springboot-whychoose">
            <h2 className="springboot-whychoose-main-heading">Why Choose Our Course?</h2>
            <p className="springboot-whychoose-main-paragraph">
                Discover the key benefits of mastering Backend Engineering with the JAVA Spring
                Framework.
            </p>
            <div className="springboot-whychoose-card-container">
                {CardData.map((data, index) => (
                    <Card {...data} />
                ))}
            </div>
        </div>
    );
};

export default WhyChoose;
