/**
 * keys : (courseIdentifiers) (don't change)
 * courseName : (availableCourses) name of current course that will be stored in local storage (don't change)
 * courseTitle : display heading of a course
 */

//TODO: to complete courseId Mappings

const availableCourseInfo = {
    Response: {
        courseName: "renaissance",
        courseTitle: "Renaissance for Coding Interviews ",
        courseDescription:
            "Master Data Structures & Algorithms and System Design. Crack Top-notch Tech roles.",
        courseId: 1,
    },
    LLD: {
        courseName: "low-level-design",
        courseTitle: "Low Level Design for Professionals",
        courseDescription:
            "Design and write highly extensible, maintainable and readable code. Mandatory skill for Senior Engineers.",
        courseId: 2,
    },
    CRASH_COURSE: {
        courseName: "crash-course",
        courseTitle: "Data Structures & Algorithms Essentials",
        courseDescription:
            "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
    },
    REACT: {
        courseName: "react",
        courseTitle: "Become a Brilliant Front-End Engineer",
        courseDescription:
            "Master the skills of a front-end engineer and build modern & responsive website applications.",
    },
    CPP: {
        courseName: "cpp",
        courseTitle: "Begin with Programming in C++",
        courseDescription:
            "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
    },
    PYTHON: {
        courseName: "python",
        courseTitle: "Begin with Programming in Python",
        courseDescription:
            "Become a Python pro and unlock the door to endless career opportunities",
    },
    JAVA_SPRINGBOOT: {
        courseName: "java-springboot",
        courseTitle: "Java Springboot",
        courseDescription:
            "Learn how to design robust and extensible microservices with Java Springboot",
    },
};

const upcomingCoursesInfo = {
    // CPP: {
    //     courseName: "cpp",
    //     courseTitle: "Begin with Programming in C++",
    //     courseDescription: "Coming Soon ",
    //     // "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
    // },
    // PYTHON: {
    //     courseName: "Python",
    //     courseTitle: "Begin with Programming in Python",
    //     courseDescription: "Coming Soon ",
    //     // "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
    // },
};

const NEW_COURSES = ["react", "java-springboot"];

const COURSE_STATUS = {
    SUBSCRIBED: "SUBSCRIBED",
    ON_FREE_TRIAL: "ON_FREE_TRIAL",
    ON_PAUSE: "ON_PAUSE",
    EXPIRED: "EXPIRED",
    START_TRIAL: "START_TRIAL",
    NONE: "NONE",
};

const COURSE_STATUS_BUTTON_TEXT_MAP = {
    SUBSCRIBED: "Continue Learning",
    ON_FREE_TRIAL: "Continue Learning",
    ON_PAUSE: "Resume Now",
    EXPIRED: "Upgrade Now",
    START_TRIAL: "Start 7 Day Free Trial",
    NONE: "Coming Soon",
};

module.exports = {
    availableCourseInfo,
    upcomingCoursesInfo,
    COURSE_STATUS,
    COURSE_STATUS_BUTTON_TEXT_MAP,
    NEW_COURSES,
};
