import axios from "axios";

export const submitUserDetails = async (formData) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_MISC_URL}/addUserDetails`, 
            formData, 
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error submitting user details:", error);
        return null;
    }
};
