import React from "react";
import mixpanel from "mixpanel-browser";
import googleIcon from "../../../resources/images/google.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    signUpValidationSchema,
    setPasswordValidationSchema,
    freeTrialValidationSchema,
    otpValidationSchema,
    passwordValidationSchema,
} from "../../../Utilities/Validation";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { signUp, resendOTP } from "./SignUpClient";
import { CURRENT_AUTH_FLOW, SIGNUP_AND_LOGIN_PAGE_STEPS } from "../../../Constants/GlobalConstants";
import OtpInput from "react-otp-input";
import "./styles/signup.css";
import "react-phone-input-2/lib/style.css";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { changePassword } from "../../SetPassword/SetPasswordClient";
import "./../../Common/styles/CommonFunction.css";
import Loader from "../../Loader/Loader";
import Header from "../../Header/Header";
import PasswordValidatePopup from "../PasswordValidatePopup/PasswordValidatePopup";
import { Formik, Form } from "formik";
import { PasswordField } from "../../Common/PasswordField";
import redirectToLandingPageStrategy from "../../../courses/Strategies/LandingPageRedirectionStrategy";
import CompanyAlumni from "./CompanyAlumni";
import { ALUMNI_CONTENT } from "../../../Constants/LogInFlowConstants";
import useWindowDimensions from "../../../hooks/UseWindowDimensions";
import { Helmet } from "react-helmet-async";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const SignUp = ({ setCouponCode }) => {
    const { windowWidth } = useWindowDimensions();
    const query = useQuery();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [otp, setOtp] = useState("");
    const [signUpSubmitError, setSignUpSubmitError] = useState(false);
    const [signUpSubmitErrorMessage, setSignUpSubmitErrorMessage] = useState("");
    const [signUpAndLoginStep, setSignUpAndLoginStep] = useState(
        SIGNUP_AND_LOGIN_PAGE_STEPS.SIGNUP
    );
    const [confirmPwdSubmitErrorMessage, setConfirmPwdSubmitErrorMessage] = useState("");
    const [errorObject, setErrorObject] = useState({ email: "", otpAndPassword: "", user: "" });
    const [counter, setCounter] = useState(45);
    const { loggedIn, showOtpScreen, currentLoggedInUserEmail, setStatesAfterIsSubscribed } =
        useGlobalContext();

    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const redirectUser = (link) => navigate(link);
    const handleOtpChange = (otp) => setOtp(otp);

    const redirectIfLoggedIn = () => {
        // if (loggedIn) navigate("/crack-coding-interviews");
        if (loggedIn) redirectToLandingPageStrategy();
    };

    useEffect(() => {
        setEmail(currentLoggedInUserEmail);
    }, [currentLoggedInUserEmail]);

    const resetCounter = function () {
        if (!counter) {
            setCounter(30);
        }
        resendOTP(email, CURRENT_AUTH_FLOW.SIGNUP);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(
            signUpValidationSchema,
            setPasswordValidationSchema,
            freeTrialValidationSchema
        ),
    });

    const onSubmit = () => {
        mixpanel.track("Clicked on Send OTP");
        signUpValidationSchema
            .validate({ email: email })
            .then(() => {
                //valid input fields
                let temp = { ...errorObject };
                temp.email = "";
                setErrorObject(temp);
                setLoaded(false);
                signUp(
                    email,
                    setSignUpSubmitError,
                    setSignUpSubmitErrorMessage,
                    setSignUpAndLoginStep,
                    setLoaded
                );
            })
            .catch((err) => {
                let temp = { ...errorObject };
                temp.email = err.errors;
                setErrorObject(temp);
            });
    };

    const onConfirmOtpAndPassword = (password) => {
        mixpanel.track("Clicked on Confirm OTP and Set Password");
        otpValidationSchema
            .validate({ otp: otp })
            .then(() => {
                let temp = { ...errorObject };
                temp.otpAndPassword = "";
                setErrorObject(temp);
                setLoaded(false);
                changePassword(
                    email,
                    otp,
                    password,
                    setSignUpAndLoginStep,
                    setConfirmPwdSubmitErrorMessage,
                    setLoaded,
                    redirectUser,
                    setStatesAfterIsSubscribed
                );
            })
            .catch((err) => {
                let temp = { ...errorObject };
                temp.otpAndPassword = err.errors;
                setErrorObject(temp);
            });
    };

    const onKeyDown = (triggerOnSubmit) => (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            triggerOnSubmit();
        }
    };

    const getProgress = () => {
        return signUpAndLoginStep === SIGNUP_AND_LOGIN_PAGE_STEPS.SIGNUP ? (
            <section className="signup-setup-progress-wrapper">
                <div className="signup-setup-progress-success"></div>
                <div></div>
                <div></div>
            </section>
        ) : signUpAndLoginStep === SIGNUP_AND_LOGIN_PAGE_STEPS.CHANGE_PASSWORD ? (
            <section className="signup-setup-progress-wrapper">
                <div className="signup-setup-progress-success"></div>
                <div className="signup-setup-progress-success"></div>
                <div></div>
            </section>
        ) : signUpAndLoginStep === SIGNUP_AND_LOGIN_PAGE_STEPS.CHANGE_PASSWORD ? (
            <section className="signup-setup-progress-wrapper">
                <div className="signup-setup-progress-success"></div>
                <div className="signup-setup-progress-success"></div>
                <div></div>
            </section>
        ) : (
            <></>
        );
    };

    const signupSetup = () => {
        return (
            <section className="signup-form-container">
                <section className="signup-form-wrapper">
                    {!loaded && <Loader />}
                    <h1 className="sign-up-header">Sign up</h1>
                    <p className="signup-progress-caption">Let's get started</p>
                    {getProgress()}
                    <p className="form-details-input-title">Email</p>
                    <input
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        type="email"
                        required
                        onKeyDown={onKeyDown(onSubmit)}
                    />
                    <div>
                        {errorObject.email && <p className="error-text">{errorObject.email}</p>}
                    </div>
                    <div>
                        {signUpSubmitErrorMessage && (
                            <p className="error-text"> {signUpSubmitErrorMessage} </p>
                        )}
                    </div>
                    <div className="common-blue-gradient-CTA" onClick={onSubmit}>
                        Send OTP
                    </div>
                    <p className="login-content-OR">OR</p>
                    <div
                        className="login-content-google-CTA "
                        onClick={() => {
                            mixpanel.track("Clicked on sign up with google");
                            window.open(process.env.REACT_APP_GOOGLE_OAUTH_LOGIN_URL, "_self");
                        }}
                    >
                        <img src={googleIcon} />
                        <p>Sign up with Google</p>
                    </div>
                    <p className="signup-setup-login">
                        Already have an account?{" "}
                        <span
                            className="login-text"
                            onClick={() => {
                                mixpanel.track("Clicked on Already have an account? Login");
                                if (query.get("redirect_url")) {
                                    navigate(`/login?redirect_url=${query.get("redirect_url")}`);
                                } else {
                                    navigate("/login");
                                }
                            }}
                        >
                            Login
                        </span>
                    </p>
                </section>
            </section>
        );
    };

    const setNewPassword = () => {
        return (
            <Formik
                initialValues={{
                    password: "",
                    confirmPassword: "",
                }}
                validationSchema={passwordValidationSchema}
                onSubmit={(values) => {
                    onConfirmOtpAndPassword(values.password);
                }}
            >
                {(formik) => (
                    <Form style={{ width: "100%" }}>
                        <div style={{ position: "relative" }}>
                            <PasswordField label="Set Password" name="password" />
                            <div className="password-validate-popup">
                                <PasswordValidatePopup password={formik.values.password} />
                            </div>
                        </div>
                        <PasswordField label="Confirm Password" name="confirmPassword" />
                        <button
                            className="signup-otp-confirm-btn"
                            type="submit"
                        >
                            Confirm OTP and Set Password
                        </button>
                    </Form>
                )}
            </Formik>
        );
    };

    const setOtpAndPassword = () => {
        return (
            <section className="signup-otp-password-wrapper">
                {!loaded && <Loader />}
                <section className="signup-otp-pwd-form">
                    <h1 className="sign-up-header">Sign up</h1>
                    <p className="signup-progress-caption">Verify with OTP</p>
                    {getProgress()}
                    <p className="otp-sent-heading">OTP has been sent to {email}</p>
                    <OtpInput
                        value={otp}
                        className="signup-otp-input-wrapper"
                        onChange={handleOtpChange}
                        numInputs={6}
                    />

                    {confirmPwdSubmitErrorMessage && <p className="error-text">Invalid OTP</p>}
                    {errorObject.otpAndPassword && (
                        <p className="error-text">{errorObject.otpAndPassword}</p>
                    )}

                    <p className="signup-otp-password-timer">
                        <span onClick={resetCounter}>Resend OTP</span> in : 00:{counter}
                    </p>
                    <p className="signup-otp-password-note">
                        Note: The email may be in your spam folder
                    </p>

                    {setNewPassword()}
                </section>
            </section>
        );
    };

    return (
        <>
            <Helmet>
                <title>Sign Up | Programming Pathshala</title>
                <meta
                    name="description"
                    content="Sign up on Programming Pathshala to get access to our coding courses."
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/signup" />
            </Helmet>
            {redirectIfLoggedIn()}
            <div className="header-sticky">
                <Header setCouponCode={setCouponCode}/>
            </div>

            {signUpAndLoginStep === SIGNUP_AND_LOGIN_PAGE_STEPS.SIGNUP && !showOtpScreen ? (
                <section className="signup-wrapper">
                    {windowWidth > 600 && <CompanyAlumni alumni={ALUMNI_CONTENT[0]} />}
                    {signupSetup()}
                </section>
            ) : (
                <section className="signup-wrapper">
                    {windowWidth > 600 && <CompanyAlumni alumni={ALUMNI_CONTENT[1]} />}
                    {setOtpAndPassword()}
                </section>
            )}
        </>
    );
};
