import React, { useState, useEffect } from "react";
import "./styles/IntroDesktop.css";
import { TagsData } from "./SpringBootData";
import PhoneInput from "react-phone-input-2";
import { submitUserDetails } from "./submitUser";
import SuccessFullSubmission from  "../../img/OBJECTS.png";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
const IntroDesktop = () => {
    const [formData, setFormData] = useState({
        phone: "",
        email: "",
        name: "",
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    const handleFormSubmission = async (e) => {
        e.preventDefault(); 
        setLoading(true);
        setError(""); 

        if(!formData.name || !formData.email || !formData.phone) {
            setError("Please first fill all the details.");
            setLoading(false);
            return;
        }

        let formattedPhone = formData.phone.replace(/\D/g, ""); 
        if (!formattedPhone.startsWith("+91")) {
            formattedPhone = "+91" + formattedPhone.slice(-10); 
        }
    
        const indianPhoneRegex = /^\+91[6-9]\d{9}$/; 
    
        if (!indianPhoneRegex.test(formattedPhone)) {
            setError("Please enter a valid phone number.");
            setLoading(false);
            return;
        }


        try {
            const response = await submitUserDetails(formData);
            if (response) {
                setShowPopup(true); 
                setFormData({ phone: "+91", email: "", name: "" });
            } else {
                setError("Failed to submit details. Please try again.");
            }
        } catch (error) {
            setError("Something went wrong. Please check your internet connection.");
            console.error("Error in form submission:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto"; // Cleanup when component unmounts
        };
    }, [showPopup]);

    useEffect(() => {
        setError("");
    },[formData]);

    return (
        <div className="springboot-intro-desktop">
            <div className="springboot-intro-banner">
                <div className="springboot-intro-content">
                    <h1 className="springboot-intro-heading">
                        Learn Backend Engineering in JAVA Spring Boot Framework
                    </h1>
                    <h2 className="springboot-intro-description">
                        Become a skilled Java Backend Developer! Master Spring frameworks, JPA, Hibernate, and more to crack backend <br/> developer interviews.
                    </h2>
                    <div>
                        <div className="tags-container">
                            {TagsData.map((data) => (
                                <div key={data.id} className="tag">
                                    {data.text}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="consultation-container">
                    <p className="consultation-title">
                        Book a <span className="free">FREE</span> Consultation
                    </p>
                    <form className="consultation-form">
                        <label className="form-label">
                            Name
                            <input 
                                className="form-input" 
                                type="text" 
                                placeholder="Enter your name" 
                                value={formData.name} 
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            />
                        </label>
                
                        <label className="form-label">
                            Email address
                            <input 
                                className="form-input" 
                                type="email" 
                                placeholder="Enter your email" 
                                value={formData.email} 
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                        </label>
                
                        <label className="form-label">
                            Phone number
                            <PhoneInput
                                country={"in"}
                                value={formData.phone}
                                className="phone-input"
                                onChange={(phone) => setFormData({ ...formData, phone })}
                            />
                            <p className="info-text">
                                We have a strict no-spam policy and will only contact you regarding your application.
                            </p>
                        </label>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                    <div className="info-container">
                        <div className="button-container">
                            <button 
                                className="primary-button" 
                                onClick={handleFormSubmission}
                                disabled={loading}
                            >
                                {loading ? "Submitting..." : "Book A Free Consultation"}
                            </button>
                            <p className="or-text">OR</p>
                            <button className="secondary-button" onClick={() => navigate('/signup')}>Start 7-Days Free Trial</button>
                            <p className="trial-info">Watch 10+ lectures and solve problems for <span>free</span></p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Popup */}
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <div className="close-button" onClick={() => setShowPopup(false)}>
                            <RxCross2/>
                        </div>
                        <img src={SuccessFullSubmission} alt="successfull submission"/>
                        <h2>Great to meet you! 🎉</h2>
                        <p>Our team will reach out soon to schedule your consultation!</p>
                        <button onClick={() => navigate('/signup')} className="start-free-trail-button">Start 7-Days Free Trial</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IntroDesktop;
