import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles/Intro.css";
import { Rating } from "@mui/material";
import { DsaBannerContents } from "./DsaCrashCourseContents/DsaIntroContents";
import dsaIntroIllustrator from "../../img/dsa-intro.webp";
import dsaIntroIllustratorMob from "../../img/dsa-intro-mob.webp";
import dsaAvatar from "../../img/dsa-intro-avatar.png";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import {
  goToDashboard,
  startFreeTrial,
} from "../../courses/Strategies/RedirectionStrategy";
import useWindowDimensions from "../../hooks/UseWindowDimensions";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";

const Intro = () => {
  const IntroBannerContents = DsaBannerContents;
  const navigate = useNavigate();
  const redirectUser = (link) => navigate(link);
  const { loggedIn, subscribed, everSubscribed, freeTrialFormFilled } =
    useGlobalContext();
  const { windowWidth } = useWindowDimensions();

  const getFreeTrialButton = () => {
    return (
      <div className="dsa-intro-cta-btn-wrapper">
        <button
          onClick={() => {
            startFreeTrial(freeTrialFormFilled, redirectUser);
          }}
        >
          Start Free Trial
        </button>
      </div>
    );
  };

  const getDashBoardButton = () => {
    return (
      <div className="dsa-intro-cta-btn-wrapper">
        <button onClick={() => goToDashboard()}>Go to Dashboard</button>
      </div>
    );
  };

  const getSignupButton = () => {
    return (
      <div
        className={`${GTM_TRACKER.START_FREE_TRIAL} dsa-intro-cta-btn-wrapper`}
      >
        {" "}
        <button onClick={() => navigate("/signup")}>
          Try 7-Day Free Trial
        </button>
      </div>
    );
  };

  const getFreeTrailOrDashboardButton = () => {
    if (!loggedIn) return getSignupButton();
    else if (!everSubscribed) return getFreeTrialButton();
    else return getDashBoardButton();
  };

  return (
    <>
      <div className="dsa-intro-wrapper">
        <div className="dsa-intro-content">
          <div className="dsa-intro-heading">
            {IntroBannerContents.introCourseHeading}
            {IntroBannerContents.introCourseDescription}
          </div>
          {windowWidth < 600 ? (
            <div className="dsa-intro-img-mob">
              <img src={dsaIntroIllustratorMob} alt="Learn Data Structures and Algorithms" />
            </div>
          ) : (
            <div></div>
          )}
          <div className="dsa-intro-points-wrapper">
            {IntroBannerContents.introPoints &&
              IntroBannerContents.introPoints.map((introPoint) => (
                <div className="dsa-intro-points">
                  <img src={introPoint.img} alt="" />
                  <p>{introPoint.text}</p>
                </div>
              ))}
          </div>
          <div className="dsa-intro-cta-wrapper">
            {getFreeTrailOrDashboardButton()}
            <span className="dsa-free-lectures">
              Watch 10+ lectures and solve problems for&nbsp;
              <span className="dsa-free-text">free </span>
            </span>
          </div>
          <div className="dsa-intro-rating-wrapper">
            <img src={dsaAvatar} alt="" />
            <div className="dsa-star-rating-wrapper">
              <p className="dsa-star-rating-text">
                <strong>5000+ students</strong>
              </p>
              <Rating
                name="half-rating-read"
                defaultValue={5}
                readOnly
                size="small"
                sx={{
                  fontSize: windowWidth <= 600 ? "4vw" : "1.8vw",
                  color: "#FCD635",
                }}
              />
            </div>
          </div>
        </div>
        {windowWidth > 600 ? (
          <img className="dsa-intro-img" src={dsaIntroIllustrator} alt="Learn Data Structures and Algorithms" />
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default Intro;
