import pythonFundamentals from "../../../img/python-advantage-fundamental.png";
import pythonDSA from "../../../img/python-advantage-dsa.png";
import pythonProgram from "../../../img/python-advantage-program.png";
import pythonParadigm from "../../../img/python-advantage-paradigm.png";


const PythonAdvantagesContents = {
    sectionTitle: <h2>Get the <strong>Programming Pathshala</strong> Advantage</h2>,
    sectionDescription: (
        <p>
            This course will equip you to start your programming journey by helping you:
        </p>
    ),
    AdvantagesList: [
        {
            type: "Understand Fundamentals",
            text: "Understand the fundamentals of programming and computer science concepts, like data types, loops, and control structures.",
            img: pythonFundamentals,
        },
        {
            type: "Learn Python Data Structures",
            text: "Become familiar with Strings, Set, Dictionary, Tuples, Lists, Enumerates, Generators, etc.",
            img: pythonDSA,
        },
        {
            type: "Write and Debug Python Programs",
            text: "Develop the Problem Solving ability to write simple programs in Python.",
            img: pythonProgram,
        },
        {
            type: "Learn Object Oriented or Functional Programming Paradigm",
            text: "Learn how to use classes in Python and master concepts of Higher Order Functions, Currying, Map/Filter/Reduce, Lambdas and much more.",
            img: pythonParadigm,
        },
    ],
};

export { PythonAdvantagesContents };
