import IntroLearnIcon from "../../../img/intro-learn-icon.png";
import IntroDevelopIcon from "../../../img/intro-develop-icon.png";
import IntroFoundationIcon from "../../../img/intro-foundation-icon.png";
import IntroBeginnerIcon from "../../../img/intro-beginner-icon.png";
import IntroTimerIcon from "../../../img/intro-timer-icon.png";

const PythonIntroBannerContents = {
    introCourseHeading: <h1>Python Full Course </h1>,
    introCourseDescription: (
        <h2 className="python-intro-desc">
            The Only Python Course You Need for <br />
            Software Engineering and Data Careers
        </h2>
    ),
    introPoints: [
        {
            text: <span>Learn Object Oriented and Functional Programming</span>,
            img: IntroLearnIcon
        },
        {
            text: <span>Build foundation for Machine Learning Applications</span>,
            img: IntroFoundationIcon
        },
        {
            text: <span>Get Problem Solving preparation for Coding Interviews</span>,
            img: IntroDevelopIcon
        },
        {
            text: <span>Beginner Friendly</span>,
            img: IntroBeginnerIcon
        },
        {
            text: <span><strong>Estimated Duration:</strong> 3 months</span>,
            img: IntroTimerIcon
        },
    ],
};

export { PythonIntroBannerContents };
