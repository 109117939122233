import React from "react";
import { FaChevronRight } from "react-icons/fa";
import "./styles/NextArrow.css";

function NextArrow(props) {
  const { onClick, top, right } = props;
  return (
    <div
      style={{ top: `${top}px`, right: `${right}%` }}
      className="next-arrow"
      onClick={onClick}
    >
      <FaChevronRight className="next-arrow-icon" />
    </div>
  );
}

export default NextArrow;
