import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useEffect } from "react";
import Intro from "./Intro";
import LogoBelt from "./LogoBelt";
import About from "./About";
import Audience from "./Audience";
import Advantage from "./Advantage";
import Curriculum from "./Curriculum";
import PreRequisites from "./PreRequisites";
import Mentor from "../Mentor/Mentor";
import SuccessStories from "./SuccessStories";
import Faq from "./Faq";
import Pricing from "./Pricing/Pricing";
import Scholarship from "./Scholarship";
import Community from "./Community";
import ExploreCourses from "../ExploreCourses/ExploreCourses";
import ExploreBlogs from "../ExploreBlogs/ExploreBlogs";
import FreeTrial from "./FreeTrial";
import { setCurrentCourse } from "../../courses/CourseUtility";
import { availableCourses } from "../../courses/CourseConstants";
import Cookies from "universal-cookie";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/SessionUtility";
import { setUTMParametersInLocalStorage, useQuery } from "../../Utilities/CommonUtilities";
import { Helmet } from "react-helmet";
import NodeJsEventBanner from "../Header/NodeJsEventBanner";
import SpringBanner from "../Header/SpringBanner";

const DsaCrashCourseRoute = ({ couponCode, setCouponCode }) => {
    setCurrentCourse(availableCourses.CRASH_COURSE);

    const cookies = new Cookies();
    const { checkSubscribed } = useGlobalContext();
    useEffect(() => {
        if (!isNULLEMPTYORUNDEFINED(cookies.get("authorization"))) checkSubscribed();
    }, []);

    const query = useQuery();
    setUTMParametersInLocalStorage(query);

    return (
        <div>
            <Helmet>
                <title>Master DSA in just 3 months | Ace Your Coding Interviews</title>
                <meta
                    name="description"
                    content="Master Data Structures & Algrithms with 120+ lectures, 150+ problems & real-time doubt resolution. Crack coding interviews! Try a 7-day free trial now!"
                />
                <link rel="canonical" href="https://renaissance.programmingpathshala.com/dsa-and-algorithms-essentials"/>
                <script type="application/ld+json">{
                `{
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "url": "https://renaissance.programmingpathshala.com/dsa-and-algorithms-essentials",
                    "name": "DSA & Algorithms Essentials - Renaissance by Programming Pathshala",
                    "description": "Master Data Structures & Algorithms with our curated DSA Essentials course. Learn problem-solving, optimize solutions, and crack coding interviews at top companies.",
                    "publisher": {
                      "@type": "Organization",
                      "name": "Programming Pathshala",
                      "url": "https://renaissance.programmingpathshala.com",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "/ppa-icon-img.png"
                      }
                    },
                    "mainEntity": {
                      "@type": "EducationalOrganization",
                      "name": "Programming Pathshala",
                      "url": "https://renaissance.programmingpathshala.com/dsa-and-algorithms-essentials",
                      "sameAs": [
                        "https://www.facebook.com/programmingpathshala",
                        "https://www.linkedin.com/company/programming-pathshala",
                        "https://www.instagram.com/programmingpathshala/"
                      ]
                    },
                    "hasCourse": {
                      "@type": "Course",
                      "name": "DSA & Algorithms Essentials",
                      "description": "Learn essential Data Structures & Algorithms, solve 150+ handpicked problems, and develop problem-solving skills to ace coding interviews.",
                      "provider": {
                        "@type": "Organization",
                        "name": "Programming Pathshala",
                        "sameAs": "https://renaissance.programmingpathshala.com/dsa-and-algorithms-essentials"
                      },
                      "courseMode": "Online",
                      "educationalLevel": "Beginner to Advanced",
                      "educationalCredentialAwarded": "Certificate of Completion",
                      "offers": {
                        "@type": "Offer",
                        "category": "Paid",
                        "price": "4999",
                        "priceCurrency": "INR",
                        "availability": "https://schema.org/InStock",
                        "url": "https://renaissance.programmingpathshala.com/signup"
                      },
                      "hasCourseInstance": {
                        "@type": "CourseInstance",
                        "courseMode": "Online",
                        "courseWorkload": "PT10H",
                        "courseSchedule": {
                          "@type": "Schedule",
                          "repeatFrequency": "Daily",
                          "repeatCount": "31"
                        },
                        "instructor": {
                          "@type": "Person",
                          "name": "Vivekanand Vivek"
                        },
                        "location": {
                          "@type": "VirtualLocation",
                          "url": "https://renaissance.programmingpathshala.com"
                        }
                      }
                    }
                }`
                }
                </script>
                <meta name="keywords" content="DSA course, Learn DSA, Data Structures & Algorithms, DSA, Coding Interview Preparation, DSA for Beginners"/>
                <meta property="og:title" content="Master DSA & Algorithms | Ace Your Coding Interviews"/>
                <meta property="og:description" content="Learn Data Structures & Algorithms with the best-curated course designed to help you crack coding interviews. Get access to 120+ lectures, 150+ handpicked problems, and real-time doubt resolution. Try a 7-day free trial now!"/>
                <meta property="og:url" content="https://renaissance.programmingpathshala.com/dsa-and-algorithms-essentials"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="https://renaissance.programmingpathshala.com/assets/dsa-course.jpg"/>
            </Helmet>
            {/* <div>
                <SpringBanner />
            </div> */}
            <div className="header-sticky">
              <Header setCouponCode={setCouponCode}/>
            </div>
            <Intro />
            <LogoBelt />
            <About />
            <Audience />
            <Advantage />
            <Curriculum />
            <PreRequisites />
            <Mentor course={availableCourses.CRASH_COURSE} />
            <SuccessStories />
            <Pricing couponCode={couponCode} setCouponCode={setCouponCode} />
            <Scholarship />
            <Community />
            <Faq />
            <ExploreCourses course={availableCourses.CRASH_COURSE} />
            <ExploreBlogs course={availableCourses.CRASH_COURSE} />
            <FreeTrial />
            <Footer />
        </div>
    );
};

export default DsaCrashCourseRoute;
