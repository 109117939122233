import {
    UNIVERSITY_FRONTEND_AUTH_URL,
    UNIVERSITY_FRONTEND_DASHBOARD_URL,
} from "../Constants/ReRoutingConstants";

export const openUniversityLoginPageWithPrefilledEmail = ({ email, collegeId }) => {
    const query = `step=login&email=${email}&univ=${collegeId}`;
    const URL = `${UNIVERSITY_FRONTEND_AUTH_URL}?${query}`;
    window.open(URL, "_self");
};

export const openUniversityDashboard = () => {
    window.open(UNIVERSITY_FRONTEND_DASHBOARD_URL, "_self");
};
