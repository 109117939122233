import { useEffect, useState } from "react";
import { useGlobalContext } from "../Contexts/GlobalContext";
import axiosInstance from "../Utilities/RequestWrapper";

export const useGetIsWhiteLabeledUser = () => {
    const [whiteLabeledUserInfo, setWhiteLabeledUserInfo] = useState([]);
    const [whiteLabeledUserInfoLoading, setWhiteLabeledUserInfoLoading] = useState(false);
    const { currentLoggedInUserEmail } = useGlobalContext();

    const getIsWhiteLabeledUser = async (customEmail) => {
        //TODO: Add the basic caching on frontend
        try {
            setWhiteLabeledUserInfoLoading(true);
            const respObj = await axiosInstance.post(
                process.env.REACT_APP_UNIVERSITY_BACKEND_URL + "/isWhiteLabeledUser",
                {
                    email: customEmail || currentLoggedInUserEmail,
                },
            );
            const { data } = respObj || {};
            const { courses = [], college_id } = data?.data || {};
            setWhiteLabeledUserInfo({ courses, collegeId: college_id });
            return { courses, collegeId: college_id };
        } catch (err) {
            console.log("WhiteLabeled Error: ", err.message);
        } finally {
            setWhiteLabeledUserInfoLoading(false);
        }
    };

    useEffect(() => {
        getIsWhiteLabeledUser();
    }, []);

    return { whiteLabeledUserInfo, whiteLabeledUserInfoLoading, getIsWhiteLabeledUser };
};
