import React from "react";
import "./styles/TargetAudience.css";
import { Button } from "@mui/material";
import { CardDataAudience as CardData } from "./SpringBootData";

const Card = ({ heading, paragraph, imageSrc, alt }) => {
    return (
        <div className="springboot-audience-card">
            <h3 className="springboot-audience-card-heading">{heading}</h3>
            <p className="springboot-audience-card-paragraph">{paragraph}</p>
            <div className="springboot-audience-image-container">
                <img src={imageSrc} alt={`${alt}`} />
            </div>
        </div>
    );
};

const TargetAudience = () => {
    return (
        <div className="springboot-audience">
            <h2 className="springboot-audience-heading">Who is this course for?</h2>
            <div className="springboot-audience-card-container">
                {CardData.map((data, index) => (
                    <Card {...data} />
                ))}
            </div>
            <div className="springboot-audience-button-container">
                <a href="/signup">
                    <Button variant="contained" className="springboot-audience-free-trial-button">
                        Start 7-Days Free Trial
                    </Button>
                </a>
            </div>
        </div>
    );
};

export default TargetAudience;
