import React from "react";
import {CppCourseForContents} from "./Content/CppAudienceContent";
import "./Styles/Audience.css";

const Audience = () => {
    const audienceContents = CppCourseForContents;
    return (
        <div className="cpp-audience-wrapper">
            <div className="cpp-audience-heading-wrapper">
                {audienceContents.sectionTitle}
            </div>
            <div className="cpp-audience-card-wrapper">
                {audienceContents.sectionOfferings &&
                    audienceContents.sectionOfferings.map((audience) => (
                        <div className="cpp-audience-card">
                            <div className="cpp-audience-img">
                                <img
                                    src={audience.img}
                                    alt="C++ for beginners" />
                            </div>
                            <div className="cpp-audience-subheading">
                                {audience.subheading}
                            </div>
                            <div className="cpp-audience-text">
                                {audience.text}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Audience;
