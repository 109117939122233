import React from "react";
import "./styles/Curriculum.css";
import { Dsatopics } from "./DsaCrashCourseContents/DsaCurriculumContent";
import downloadIcon from "../../img/download-btn-ren.png";
import TopicCard from "./CurriculumTopicCard";
import { GTM_TRACKER } from "../../GoogleAnaytics/gtmConstants";
import CurriculumPdf from "../../CurriculumPdf/DSAEssentialsCourseCurriculum.pdf";

const Curriculum = () => {
    return (
        <div className="dsa-curriculum-wrapper">
            <h2>Course Curriculum</h2>
            <p>Best in class DSA Roadmap</p>
            <div className="dsa-topics-wrapper">
                {Dsatopics && Dsatopics.map((data, index) => <TopicCard data={data} index={index} />)}
            </div>
            <a
                className={GTM_TRACKER.DOWNLOAD_CURRICULUM}
                href={CurriculumPdf}
                rel="noopener noreferrer"
                target="_blank"
            > 
                <div className="dsa-download-curriculum">
                    <img src={downloadIcon} alt="" />
                    <p>Download Detailed Curriculum</p>
                </div>
            </a> 
        </div>
    );
};

export default Curriculum;
