import beginnerImg from "../../../img/cpp-beginner.png";
import developerImg from "../../../img/cpp-developer.png";

const CppCourseForContents = {
    sectionTitle: <h2>This course is for you if you are:</h2>,
    sectionOfferings: [
        {
            img: beginnerImg,
            subheading: <p className="subHeadingCss">A Beginner</p>,
            text: <p>Looking to get started in the world of programming<br /> and build a strong foundation in C++.</p>,
        }, 
        {
            img: developerImg,
            subheading: <p className="subHeadingCss">A Developer</p>,
            text: <p>Looking to expand your skills<br /> 
            and become a C++ pro.</p>,
        }, 
    ],

};

export { CppCourseForContents };
